.people-class-menu-container {
  min-width: 240px;
  min-height: 170px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 8px;
}
.people-container {
  display: flex;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}
.people-type-container {
  display: flex;
  flex-direction: column;
  flex: 0.6;
}
.people-count-container {
  flex: 0.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.people-type-text {
  font-family: "Cabin";
  font-size: 16px;
}
.people-type-sub-text {
  font-family: "Cabin";
  font-size: 12px;
  opacity: 0.6;
  margin-top: 4px;
}
.people-count {
  font-family: "Cabin";
  font-size: 20px;
  font-weight: bold;
}
.count-button {
  margin-top: 4px;
}
.travel-class-select {
  margin-top: 24px;
}
.class-select-title {
  font-family: "Cabin";
  font-size: 13px;
  font-weight: bold;
  text-transform: capitalize;
}
.travel-class-radio-buttons {
  margin-top: 12px;
  padding-bottom: 8px;
}
.bb-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.disable {
  pointer-events: none;
  opacity: 0.3;
}
.pointer {
  cursor: pointer;
}
.error-text {
  margin-top: 8px;
  margin-bottom: 8px;
  color: red;
  font-family: "Cabin";
  font-size: 13px;
}
