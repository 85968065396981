.date-range-picker-header {
  width: 100%;
  height: 50px;
  box-shadow: 0px 3px 7px 0px #0000000d;
  /* display: flex; */
  background: #ffffff;
  padding: 4px;
  padding-left: 16px;
  align-items: center;
  color: #a0155a;
  font-family: "Cabin";
  font-weight: bold;
}
.custom-navigation-header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  display: flex;
  align-self: center;
  justify-content: space-between;
}
.reverse {
  transform: rotate(180deg);
}
.pointer {
  cursor: pointer;
}
.cell-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.date-style {
  font-family: "Cabin";
  font-size: 15px;
}
.date-price {
  margin-top: 3px;
  font-size: 9px;
  font-family: "Cabin";
  opacity: 0.5;
}
.disabled {
  opacity: 0.4;
}
.events-none {
  pointer-events: none;
}
.restrict-cursor-events {
  pointer-events: none !important;
}

.date-range-picker-header .MuiAlert-standardError {
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);
  float: right;
}
.currentSelection{
  /* float: left; */
  padding: 10px !important;
   box-shadow: inset 0 -2px 0 0 #a0155a;
}
.defaultDate{
  /* float: left; */
   padding: 10px !important;
}