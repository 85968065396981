.view-booking-page-container {
  /* width: 98vw;
  height: 100vh;
  overflow: scroll; */
  width: 100vw;
  height: 100vh;
  display: table-cell;
}

/* .baggageDisplay {
  max-height: 7.5% !important;
  overflow: auto !important;
}

.cancellationDisplay {
  max-height: 7.5% !important;
  overflow: auto !important;
}

.dateChangeDisplay {
  max-height: 7.5% !important;
  overflow: auto !important;
} */

.logo-small {
  height: 48px;
}

.logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.view-booking-header-container {
  /* padding-top: 24px; */
  background: linear-gradient(to bottom, #26173d, #340f2f);
}

.header-page-title-container {
  height: 60px;
  background-color: rgba(159, 21, 90, 0.1);
  /*margin-top: 24px;*/
}

.header-page-title-text {
  display: flex;
  align-items: center;
  height: 60px;
  color: #ffffff;
  font-family: "Cabin";
  font-weight: bold;
  font-size: 15px;
  margin-left: 12px;
}

.header-page-icon {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-icon-fill {
  fill: #ffffff !important;
  opacity: 0.5;
}

.header-page-title-items {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.view-booking-itenary {
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  padding-right: 40px;
  padding-bottom: 40px;
}

.view-booking-itenary-title {
  font-family: "Cabin";
  font-size: 16px;
  font-weight: bold;
}

.view-booking-fare-summary {
  display: flex;
  margin-top: 32px;
  flex-direction: column;
}

.view-booking-fare-summary-title {
  font-family: "Cabin";
  font-size: 16px;
  font-weight: bold;
}

.view-booking-details-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}

.table {
  width: 100%;
  border-collapse: unset;
}

.th {
  height: 40px;
  font-size: 14px;
  font-family: "Cabin";
  font-weight: bold;
  opacity: 0.5;
  width: 25% !important;
}

.ta-left {
  text-align: left;
}

.ta-right {
  text-align: right;
}

.ta-center {
  text-align: center;
}

.td {
  height: 24px;
  font-size: 14px;
  font-family: "Cabin";
  width: 25% !important;
}

.mt-24 {
  margin-top: 24px;
}

.div-splitter {
  height: 1px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.bold {
  font-weight: bold;
}

.font-17 {
  font-size: 17px !important;
}

.travel-protection-add-now {
  font-size: 14px;
  font-family: "Cabin";
  font-weight: bold;
  color: #9f155a;
  cursor: pointer;
}

.coupon-container {
  display: flex;
}

.coupon-input-box {
  flex: 0.7;
}

.coupon-submit-button-container {
  flex: 0.3 !important;
}

.coupon-submit-button {
  margin-left: 12px !important;
  border-radius: 4px !important;
  background-image: linear-gradient(96deg, #db2a5f, #a0155a) !important;
  color: #ffffff !important;
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  box-shadow: 0px 0px 4px 0px #00000052 !important;
  font-size: 14px !important;
  font-family: "Cabin" !important;
  font-weight: bold !important;
  padding: 10px !important;
  max-width: 45% !important;
  width: 40% !important;
}
.coupon-submit-button:disabled {
  opacity: 0.5 !important;
}
.plus-minu-button {
  margin-left: 12px;
  border-radius: 4px;
  background-image: linear-gradient(96deg, #db2a5f, #a0155a);
  color: #ffffff;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px #00000052;
  font-size: 14px;
  font-family: "Cabin";
  font-weight: bold;
}

.view-booking-page-container .MuiInputLabel-root {
  font-size: 14px !important;
  font-family: "Cabin" !important;
  background-color: #ffffff;
  position: relative;
  top: -4px !important;
  padding: 0px 8px 0px 8px !important;
}

.view-booking-itenary-container {
  margin-top: 28px;
}

.view-booking-tickets-container {
  background-color: #ffffff;
  padding: 24px 12px;
  /* box-shadow: rgb(0 0 0 / 18%) 0px 0px 4px 0px; */
  border-radius: 4px;
  padding-bottom: 32px;
  margin-top: 4px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-28 {
  margin-bottom: 28px;
}

.view-booking-tickets-container-info {
  display: flex;
  align-items: center;
  border: 1px solid #db2a5f;
  border-left: 3px solid #db2a5f;
  border-radius: 3px;
  font-size: 13px !important;
  font-family: "Cabin" !important;
  color: #db2a5f;
  padding: 10px;
  background-color: rgba(219, 42, 95, 0.1);
}

.ml-0 {
  margin-left: 0px !important;
}

.continue-button-container {
  display: block;
  width: 100%;
  justify-content: center;
  display: flex;
}

.email-field-container {
  margin-top: 24px;
  width: 30%;
}

.passenger-detail-field-container {
  display: flex;
  margin-top: 24px;
}

.pax-type {
  font-size: 11px !important;
  font-family: "Cabin";
  opacity: 0.5 !important;
  font-weight: normal !important;
  margin-top: 4px;
}

.passenger-text {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  width: 10%;
  font-size: 13px;
  font-family: "Cabin";
  font-weight: bold;
}

.passenger-fields {
  display: flex;
  width: 93%;
  margin-top: 8px;
}

.passenger-frequent-flyer-fields {
  width: 83% !important;
}

.passenger-fields-readonly {
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  width: 100%;
  padding: 0.5% 2%;
}

.block-display {
  display: block;
}

.mt-11 {
  margin-top: 11px !important;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-8 {
  margin-right: 8px;
}

.flex-grow {
  flex-grow: 1;
}

.min-width-100 {
  min-width: 100px;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-120 {
  min-width: 120px;
}

.min-width-80 {
  min-width: 80px;
}

.rules-container {
  background-color: #ffffff;
  border-radius: 4px;
  /* box-shadow: rgb(0 0 0 / 18%) 0px 0px 4px 0px; */
  margin-top: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rules-text {
  font-family: "Cabin";
  font-size: 12px;
  font-weight: bold;
  color: #a0155a;
}

.email-alert-container {
  display: flex;
}

.alert-check-container {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-left: 16px;
}

.alert-text-container {
  font-family: "Cabin";
  font-size: 13px;
}

.view-booking-tickets-container-success {
  display: flex;
  align-items: center;
  border: 1px solid #36b37e;
  border-left: 3px solid #36b37e;
  border-radius: 3px;
  font-size: 13px !important;
  font-family: "Cabin" !important;
  color: #00875a;
  padding: 16px;
  background-color: #e3fcef;
}

.insurance-container {
  background-color: #ffffff;
  border-radius: 4px;
  /* box-shadow: rgb(0 0 0 / 18%) 0px 0px 4px 0px; */
  margin-top: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.insurance-container-header {
  font-family: "Cabin";
  font-size: 15px;
  font-weight: bold;
}

.insurance-container-details {
  margin-top: 32px;
  display: flex;
}

.insurance-container-details-icon-fill {
  fill: #00875a !important;
}

.feature-icon-fill {
  fill: #00875a !important;
}

.insurance-container-details-container {
  margin-left: 24px;
}

.insurance-container-details-container-text {
  margin-top: 16px;
  display: flex;
}

.feature-text {
  font-family: "Cabin";
  font-size: 12px;
  margin-left: 8px;
}

.flex-center {
  display: flex;
  align-items: center;
  min-width: 160px;
}

.frequent-flyer-container {
  margin-top: 32px;
}

.billing-field-container {
  margin-top: 24px;
  width: 50%;
}

.width-100 {
  width: 100% !important;
  display: flex;
}

.flex-5 {
  flex: 0.5;
}

.mt-0 {
  margin-top: 0px !important;
}

.static-info-container {
  display: flex;
  flex-direction: column;
}

.static-info-title {
  font-family: "Cabin";
  font-size: 12px;
}

.static-info-description {
  margin-top: 4px;
  font-family: "Cabin";
  font-size: 14px;
}

.mt-12 {
  margin-top: 12px;
}

.review-booking-flightdetails {
  padding-right: "inherit";
  width: -WEBKIT-FILL-AVAILABLE;
}

.no-results-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  margin-top: 60px;
}

.no-results-text {
  margin-top: 24px;
  font-family: "Cabin";
  font-size: 14px;
  opacity: 0.7;
}

/*********** Below Code for New Review Booking Dtls Desing ************/
.flt-plusDisplayTxt .flt-redText {
  color: #a0155a;
  font-size: 10px;
  line-height: 9px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.flt-bkg-dtls-section-review {
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: default;
}

.view-booking-details-container .flt-bkg-heading {
  color: #9f155a;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 3px;
  border-bottom: 0px dotted #e5e5e5 !important;
}

.flt-bkg-itinerary-section-review {
  min-height: 100px;
  padding: 0px 5px !important;
}

.flt-bkg-itinerary-section:not(:first-child):not(:last-child) {
  border-bottom: 1px dotted #e5e5e5;
}

.flt-bkg-heading-bg {
  background-color: #f2f2f2;
  height: 35px;
  border-radius: 10px;
  padding-top: 8px;
}

.flt-bkg-main-heading {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #000000;
}

.flt-bkg-sub-heading {
  font-size: 14px;
  font-weight: 500;
  padding: 0px 12px 3px;
  color: #6a0537;
  border-bottom: 1px dotted #e5e5e5;
}

.flt-bkg-main-heading small {
  font-size: 12px;
  color: #666161;
}

.flt-bkg-highlight-badge {
  color: #212529 !important;
  font-size: 10px;
  padding: 0;
  text-transform: capitalize;
  margin-left: 0;
  text-align: left;
  font-weight: 500;
}

.flt-bkg-highlight-label {
  background-color: #fdeaea;
  border: 1px solid #f7dbdb;
  padding: 1px 4px 8px;
  border-radius: 4px;
}

.flt-bkg-highlight-label .flt-bkg-imp-badge-label {
  background-color: #bf0b0b;
  color: #ffffff;
  font-size: 11px;
  padding: 4px 7px;
  text-transform: uppercase;
}

.flt-bkg-highlight-label .flt-bkg-imp-msg {
  font-size: 13px;
  color: #c05353;
}

.flt-bkg-information-panel .flt-ic {
  width: 25px;
  height: 25px;
}

.flt-bkg-information-panel .flt-name {
  font-size: 13px;
}

.flt-bkg-information-panel .flt-number,
.flt-bkg-information-panel .flt-route-cls {
  font-size: 12px;
  color: #999999;
}

.flt-bkg-information-panel .flt-operated-by {
  font-size: 10px;
  color: #999999;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.25em;
}

.flt-bkg-information-panel .flt-departure-info {
  text-align: right;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.flt-bkg-information-panel .flt-arrival-info {
  text-align: left;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.flt-bkg-information-panel .flt-departure-info .flt-origin {
  padding-right: 5px;
}

.flt-bkg-information-panel .flt-arrival-info .flt-destination {
  padding-left: 5px;
}

.flt-bkg-information-panel .flt-departure-info .flt-airport-code,
.flt-bkg-information-panel .flt-arrival-info .flt-airport-code {
  font-size: 17px;
}

.flt-bkg-information-panel .flt-departure-info .flt-time,
.flt-bkg-information-panel .flt-arrival-info .flt-time {
  font-size: 17px;
  font-weight: bold;
}

.flt-bkg-information-panel .flt-departure-info .flt-date,
.flt-bkg-information-panel .flt-arrival-info .flt-date {
  font-size: 11px;
}

.flt-bkg-information-panel .flt-departure-info .flt-airport-nm,
.flt-bkg-information-panel .flt-arrival-info .flt-airport-nm {
  font-size: 12px;
  color: #999999;
}

.flt-bkg-information-panel .flt-duration-info .flt-duration {
  font-size: 12px;
  color: #999999;
}

.flt-bkg-layover-info .layover-info {
  border-bottom: 1px dotted #e2e2e2;
  text-align: center;
  padding: 12px;
  height: 10px;
}

.flt-bkg-layover-info .layover-info .layover-time {
  background-color: #ffffff;
  font-size: 12px;
  color: #999999;
  position: relative;
  left: 0;
  top: -2px;
}

.flt-bkg-badge-time {
  background-color: #e1edf8;
  color: #4b5a67 !important;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  border-radius: 8px;
  left: 0;
  top: -2px;
}

.layover-badge-time {
  background-color: #fdeaea;
  color: #ce265e !important;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  border-radius: 8px;
  left: 0;
  top: -2px;
}

/* .baggageInfoTable {
  width: 100%;
  text-align: center;
  font-size: 13px;
  padding: 5px;
  background-color: #fff;
}

.baggageInfoTable thead {
  border-bottom: 1px dotted #000;
} */

/* .baggageTableContainer {
  margin-bottom: 10px !important;
  border: 1px dotted #000;
} */

.fs-sub-heading {
  font-size: 13px;
  color: #340f34;
  font-weight: 500;
}

.back-btn {
  margin-left: 12px;
  border-radius: 4px;
  color: #9f155a;
  border: 1px solid rgba(159, 21, 90, 0.5);
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-family: "Cabin";
  font-weight: bold;
  width: 40% !important;
  max-width: 45% !important;
}

.back-button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.view-booking-page-container .MuiOutlinedInput-root {
  padding: 4px !important;
}

.view-booking-page-container .MuiAutocomplete-endAdornment .MuiIconButton-root {
  padding: 0px !important;
}

.view-booking-page-container .MuiOutlinedInput-input {
  font-size: 14px !important;
}

.view-booking-page-container .MuiAutocomplete-input {
  font-size: 14px !important;
  padding: 7.5px 55px 7.5px 6px !important;
}

.view-booking-page-container .MuiAutocomplete-popper {
  font-size: 14px !important;
}

.loader-box-content {
  background-color: #ffffff;
  padding: 15px 35px;
  border-radius: 8px;
  text-align: center;
}

.flt-main-heading {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #6a0537;
}

.loader-cls {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.decl-mandatory-txt {
  color: #f44336;
  font-size: 14px;
}

.decl-info-bg {
  background-color: #efefef;
  border-radius: 5px;
  padding: 15px;
}

.decl-main-heading {
  font-size: 16px;
  font-weight: 500;
  color: #a5175a;
  text-transform: uppercase;
  border-bottom: 0.3px solid #cccccc;
}

.decl-sub-heading {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.decl-normal-txt {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.timerFAB {
  position: absolute;
  right: 10%;
  bottom: 15%;
  min-width: 100px;
}

.timerFAB .sessionTimerred {
  position: fixed !important;
  padding: 15px;
  font-size: 45px !important;
  border-radius: 60px;
  background: rgba(219, 42, 95, 0.1);
  min-width: 150px !important;
}

.timerFAB .sessionTimergreen {
  position: fixed !important;
  padding: 15px;
  font-size: 45px !important;
  border-radius: 60px;
  background: rgba(219, 42, 95, 0.1);
  min-width: 150px !important;
}

.timerFAB .sessionText {
  position: fixed !important;
  padding: 10px;
  font-size: 12px;
  margin: 0px 1%;
}

.css-lntp72-MuiButtonBase-root-MuiFab-root {
  position: fixed !important;
}

.baggageErrorText {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #ff0000 !important;
  text-transform: capitalize;
}

.header-icon-fill {
  fill: #ffffff !important;
  opacity: 1 !important;
}

.reviewBookingHeading {
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0px 0px 0px;
  display: inline-block;
}

.review-itenary-container-header-details {
  font-family: "Cabin" !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  text-transform: capitalize !important;
}

.Mui-selected {
  color: #9f155a !important;
}

.view-booking-page-container .MuiTableCell-head {
  font-weight: 600 !important;
  font-size: 16px !important;
  opacity: 1 !important;
}

.backToSearch {
  font-size: 13px;
  color: maroon;
  font-weight: 600;
  text-align: end;
  display: block;
  width: 100% !important;
  text-decoration: underline;
  cursor: pointer;
}
.fareSummaryTable .MuiTableCell-root {
  padding: 5px !important;
}
.disabled {
  pointer-events: none !important;
  opacity: 0.4 !important;
}

.pt-markup-tf {
  font-size: 13px;
  font-weight: 500;
  color: grey;
}

.read-only-value {
  font-size: 13px;
  font-weight: 500;
  color: #4e4a4e;
}
