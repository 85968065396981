.operating-ond-container {
margin: 25px;
width: 100vw;
height: 100%;
}
  
.operating-ond-content {
padding: 30px;
margin: 25px 0px;
border-radius: 14px !important;
background: #ffffff;
box-shadow: 0px 0px 5px 3px #0000000a !important;
font-family: "Cabin";
font-weight: 300;
font-size: 14px;
}

.operating-ond-heading {
    font-family: "Cabin";
    font-weight: 300;
    font-size: 14px;
    opacity: 0.7;
    text-transform: uppercase;
  }

  .btn-primary{
    background-color: #ffffff;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #a0155a;
    color: #a0155a;
    border-radius: 4px;
}

.btn-primary:hover{
    background-color: #a0155a;
    color: #ffffff;
}

.btn-secondary{
    background-color: #ffffff;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #a0155a;
    color: #a0155a;
    border-radius: 4px;
}

.valid-text{
    font-family: "Cabin";
    font-weight: 500;
    font-size: 16px;    
    color: #a0155a;
}

.operating-ond-list-container {
    display: flex;
    width: 100%;
    margin-top: 24px;
  }