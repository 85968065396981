.offline_booking,
.offline-itenary-container-import,
.offline-passanger-container-import,
.offline-baggage-container-import,
.offline-supplierfare-container-import,
.offline-clientfare-container-import,
.offline_bookingfooter {
  text-align: center;
}

.bold-dark {
  font-weight: bold !important;
  color: #a39d9d !important;
}


.full-width-item {
  width: 100%;
}


.bordered-item {
  border: 1px solid #ccc; 
  padding: 10px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

.text-left{
  text-align: left;
  margin-left: 30px;
}

.small-text {
  font-size: 0.875rem; 
}

.footerlefttext{
  text-align: left;
}

.headerleft{
  text-align: left;
}





.text-right{
 
  display: flex;
  padding:16px;
  flex-direction: row;
 justify-content: flex-end;

  
}





