.supplier-view-edit-drawer-container {
    padding: 24px;
    /* margin-top: 32px; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .supplier-view-edit-drawer-if-container {
    font-family: "Urbanist";
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-top: 24px;
    margin-bottom: 10px !important;
  }
  .supplier-view-edit-drawer-if-line {
    height: 1px;
    border-bottom: 1px solid #000000;
    opacity: 0.1;
    width: 10% !important;
    margin-left: 16px;
    margin-right: 16px;
  }
  .supplier-view-edit-drawer-input-container {
    display: flex;
    width: 100%;
    margin-top: 24px;
  }
  .margin-right-16 {
    margin-right: 16px;
  }
  .config-input-flex-1 {
    flex: 1;
  }
  .config-margin-top-32 {
    margin-top: 40px;
  }
  .add-supplier-button-container-update-new {
    font-family: "Cabin";
    font-weight: 300;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #a0155a;
    color: #a0155a;
    border-radius: 4px;
    margin-top: 24px;
    margin-right: 24px;
    margin-left: 24px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-supplier-button-container-update-new-button {
    cursor: pointer;
    text-align: center;
  }
  .edit-view-drawer-heading {
    font-family: "Cabin";
    font-weight: 600;
    font-size: 20px;
    margin-top: 24px;
    margin-left: 24px;
  }
  .view-supplier-drawer {
    display: flex;
    flex-direction: column;
  }
  .view-supplier-drawer-title {
    font-family: "Cabin";
    font-size: 12px;
    opacity: 0.6;
  }
  .view-supplier-drawer-description {
    font-family: "Cabin";
    font-weight: 300;
    font-size: 14px;
    margin-top: 6px;
  }
  .supplier-title-add-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .mt-0px {
    margin-top: 0px;
  }
  .supplier-title-flex-container {
    margin-top: 24px;
    font-family: "Cabin";
    font-weight: 600;
    font-size: 16px;
    margin-top: 16px;
  }
  .ticket-drawer-container{
    margin-top: 60px;
    width: auto;
  }
  .copy-selector {
    margin-top: 2%;
  }
    
  .mx-width-drawer{   
    max-width: 71vw;
  }
  /********* Multi Select Tree Additional CSS ********/
  .tree-container .selected-option{
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #e0e0e0;    
    padding: 7px 15px;    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
  }
  .tree-container .selected-option-icon{
    width: 10px;
    margin-left: 7px;
    color: rgba(0, 0, 0, 0.26);
    cursor: pointer;
    height: 22px;
  }
  .tree-dropdown .tree-container .dropdown-indicator
  {
    width: 28px;
    color: #757575 !important;
  }
  .tree-dropdown .react-tree-menu{
    width: 100% !important;    
    border-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
  }
  .vertical-option .arrow-container
  {
    position: relative;
    bottom: 11px;
  }
  .tree-dropdown .tree-container
  {
    font-family: "Cabin" !important;
    border-color: #eaeaea !important;
    font-size: 14px !important;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54) !important;
    border-radius: 4px;
  }
  .tree-dropdown .tree-container .clear-container {
      width: 25px;
      padding: 0 0.5rem;
      color: #757575 !important;
  }
  .tree-dropdown .tree-container .divider-container
  {
    width: 1px !important;
    background-color: #757575 !important;
  }
  .tree-dropdown .tree-container .options-content-container
  {
    padding: 0 1.5rem !important;
  }
  .react-tree-search-input .search-input-icon {
    width: 20px;
    color: #757575 !important;
    margin-right: 10px;
    margin-left: 7px;
}
  .tree-dropdown .tree-container.dropdown-active
  {    
    font-family: "Cabin" !important;
    border-color: #eaeaea !important;
    font-size: 14px !important;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54) !important;
    border-radius: 4px;
  }
  .react-tree-search-input {        
    font-family: "Cabin" !important;
    font-size: 14px !important;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
    padding: 7.5px 55px 7.5px 6px !important;
    max-width: unset;    
    border-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px;
  }
