.category-card-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  box-shadow: none;
  box-shadow: 0px 1px 4px 1px #00000014;
  cursor: pointer;
}
.category-card-heading {
  font-family: "Cabin";
  font-weight: bold;
  font-size: 14px;
}
.category-card-sub-heading {
  font-family: "Cabin";
  font-weight: 500;
  font-size: 13px;
  opacity: 0.5;
}
.category-person-date {
  display: flex;
  font-family: "Cabin";
  font-size: 12px;
  justify-content: space-between;
  opacity: 0.4;
}
.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.selected-border {
  border: 1px solid #000000;
}
