/* https://www.vecteezy.com/vector-art/87721-wood-fence-vectors */
.wrapper {
  position: relative;
  max-width: 1298px;
  height: auto;
  margin: 2em auto 0 auto;
  background: transparent
    url("https://www.dropbox.com/s/qq5n8w99q40wtrg/wood-fence.png?raw=1")
    no-repeat center top 15em;
}

/* https://www.vecteezy.com/vector-art/237238-dog-family-colored-doodle-drawing */
.wrapper .box {
  max-width: 70%;
  min-height: 600px;
  margin: 0 auto;
  padding: 1em 1em;
  text-align: center;
  background: transparent
    url("https://www.dropbox.com/s/ft9vhk6720t1k86/dog-family-colored-doodle-drawing.jpg?raw=1")
    no-repeat top 0em center;
}

.wrapper h1 {
  margin: 0 0 1rem 0;
  font-size: 8em;
  text-shadow: 0 0 6px #8b4d1a;
}

.wrapper p {
  margin-bottom: 0.5em;
  font-size: 1.75em;
  color: #ea8a1a;
}

.wrapper p:first-of-type {
  margin-top: 16em;
  text-shadow: none;
}

.wrapper p > a {
  border-bottom: 1px dashed #837256;
  font-style: italic;
  text-decoration: none;
  color: #837256;
}
.goBackButton {
  color: #a0155a !important;
  text-decoration: underline;
}
.wrapper p > a:hover {
  text-shadow: 0 0 3px #8b4d1a;
}

.wrapper p img {
  vertical-align: bottom;
}

@media screen and (max-width: 600px) {
  .wrapper {
    background-size: 300px 114px;
    background-position: center top 22em;
  }

  .wrapper .box {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    background-size: 320px 185px;
  }

  .wrapper p:first-of-type {
    margin-top: 12em;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.unauthorized-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.unauthorized-box {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.unauthorized-box h1 {
  margin-bottom: 1rem;
  color: #333;
}

.unauthorized-box p {
  margin-bottom: 2rem;
  color: #666;
}

.go-home-button {
  background: #9f155a;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.go-home-button:hover {
  background: #99004c;
}

.home-icon {
  margin-right: 0.5rem;
}
