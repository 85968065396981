.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  padding: 1rem;
}

.otp-input {
  width: 60px;
  height: 60px;
  text-align: center;
  color: #791346e8;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.otp-main-container .resend-txt {
  font-size: 12px;
}

.otp-main-container .srn-btn {
  text-transform: capitalize !important;
  background-image: linear-gradient(96deg, #db2a5f, #a0155a) !important;
  /* background: #9f155a !important; */
  color: #fff !important;
  border-color: #9f155a !important;
  min-width: 140px !important;
  min-height: 45px;
  font-weight: 600 !important;
}

.otp-main-container .srn-btn:disabled {
  text-transform: capitalize !important;
  background-image: linear-gradient(96deg, #0000005e, #00000042) !important;
  /* background: #9f155a !important; */
  color: #fff !important;
  border-color: #9f155a !important;
  min-width: 140px !important;
  min-height: 45px;
  font-weight: 600 !important;
}

.otp-main-container .srn-btn:hover {
  text-transform: capitalize !important;
  background-image: linear-gradient(96deg, #f72362, #a00553) !important;
  /* background: #9f155a !important; */
  color: #fff !important;
  border-color: #5f0733 !important;
  min-width: 140px !important;
  min-height: 45px;
  font-weight: 600 !important;
}

.resend-otp-t {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.otp-exp-text {
  font-size: 13px;
  color: red;
}

.otp-valid-text {
  font-size: 13px;
  color: green;
}
