.search-filters-container {
  /* background-color: #ffffff; */
  border-radius: 4px;
  /* box-shadow: 0 0 5px 1px #00000030; */
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.filter-item-container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
}
.filter-item-title {
  font-family: "Cabin";
  font-size: 16px;
  font-weight: bold;
}

.mt-24 {
  margin-top: 24px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 3px;
}
.mt-32 {
  margin-top: 32px;
}
.mr-8 {
  margin-right: 8px;
}
