.search-container {
    width: 100vw;
    height: 100%;
    /* background-color: #fffbf0; */
  }
  .confiration-navbar {
    height: 70px;
    background-color: #ffffff;
    box-shadow: 0px -1px 8px 2px #00000017;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
  }
  .nav-bar-items-container {
    display: flex;
    height: 70px;
  }
  .mr-24 {
    margin-right: 24px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mt-4 {
    margin-top: 4px;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .nav-bar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 24px;
  }
  .nav-bar-item-icon {
    opacity: 0.2;
  }
  .nav-bar-item-text {
    font-family: "Cabin";
    font-weight: 300;
    opacity: 0.7;
  }
  .search-content-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .category-drawer-opener {
    position: fixed;
    top: 40vh;
    bottom: 0;
    left: -40px;
    height: 80px;
    width: 80px;
    background: #ffffff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px 0px 5px 3px #0000000a;
  }
  .category-expand-icon-margin {
    margin-right: 8px;
  }
  .search-category-container {
    min-width: 360px;
  }
  .shadow-inner {
    height: 8px;
    box-shadow: 0px 3px 4px 1px #0000000d inset;
  }
  .search-heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 16px;
  }
  .search-heading {
    font-family: "Cabin";
    font-weight: 300;
    font-size: 14px;
    opacity: 0.7;
    text-transform: uppercase;
  }
  .search-heading-icons {
    display: flex;
  }
  .search-search-container {
    padding: 24px;
    padding-top: 0px;
    padding-left: 24px;
    padding-right: 24px;
    /* height: calc(100vh - 140px); */
    /* overflow: scroll; */
    /* margin-bottom: 48px; */
  }
  .pr-pl-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .search-table-container {
    margin-top: 16px;
    padding-bottom: 40px;
  }
  .justify-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-card-container {
    /* margin-top: 24px; */
    margin-bottom: 24px;
    /* margin-left: 24px;
    margin-right: 24px; */
  }
  .add-search-button {
    font-family: "Cabin";
    font-weight: 300;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #a0155a;
    color: #a0155a;
    border-radius: 4px;
    margin-top: 4px;
  }
  .add-search-button-container {
    display: inline-block;
    cursor: pointer;
  }
  .search-icon-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search-icon-margin {
    margin-right: 24px;
    fill: #a0155a !important;
    cursor: pointer;
  }

  .total-sales-number{
      font-size: 2em;
      font-weight: 600;
      margin-top: 20px;
  }

  .total-sales-number-country{
    font-size: x-large;
    font-weight: 600;
    margin-top: 5px;
  }
  