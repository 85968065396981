.offline-booking-container{
  padding-top: 40px;
  padding-bottom: 40px;
}
.segformdrawer-width {
  width: 40vw !important;
  max-width: 45vw !important;
}
.ticket-drawer-form {
  margin-top: 1vw !important;
}
.MuiOutlinedInput-notchedOutline {
  border: 0px solid rgba(0, 0, 0, 0.1) !important;
}
.segment-drawer-container {
  margin-top: 60px;
}
.tripTypeDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}
.addSegDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  width: -webkit-fill-available;
  padding-right: 10px;
}
.addBagDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  width: -webkit-fill-available;
  padding-right: 10px;
  min-width: 50vw;
}
.addButton {
  color: #9f155a !important;
  border: 1px solid rgba(159, 21, 90, 0.5) !important;
  text-transform: capitalize !important;
}
.importexcel-lbl {
  color: #9f155a !important;
  text-transform: capitalize !important;
}

.importbtn {
  border: 0px !important;
  background: #dfd9d99e !important;
  color: #000 !important;
  align-content: center !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
  max-width: 40vw !important;
  padding: 5px 10px !important;
  margin: 0px 10px !important;
}
.uploadbtn {
  max-width: 200px !important;
  margin: 0px 10px !important;
}
.importbtn .MuiButton-label {
  font-size: 10px !important;
  text-transform: capitalize !important;
}
.importbtn.MuiButton-outlined.Mui-disabled {
  opacity: 0.5 !important;
}
.offline-itenary-container-import {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 32px;
  color: #340f2f;
  padding: 0px 10px 0px 5px !important;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.offline-itenary-container {
  /* -webkit-box-shadow: 0px 0px 5px 4px rgba(148, 148, 148, 1);
  -moz-box-shadow: 0px 0px 5px 4px rgba(148, 148, 148, 1);
  box-shadow: 0px 0px 5px 4px rgba(148, 148, 148, 1); */
  border-bottom: 0.5px solid #eee;
  margin-bottom: 1vh;
  background: #eee;
  padding: 1vw;
  border-radius: 20px;
}
.offline-flight-itenary {
  margin-top: 2vh !important;
}
.create-booking-button-container {
  margin: 10px !important;
}
.offline-booking-remarks .MuiOutlinedInput-root {
  border: 0.5px solid #eee !important;
  min-height: 100px !important;
}
.offline-mco-dtls-bg {
  /* background-color: #ffffff; */
  /* padding: 20px 7px; */
  border-radius: 0px 0px 8px 8px;
  margin-top: 24px;
}
.offline-mco-dtls-bg .MuiFormControl-root {
  height: auto;
  border: 0.5px solid #e1e1e1 !important;
}
.itenary-passenger-details-offline {
  width: 100%;
  margin-top: 24px;
  padding: 10px;
}
.offline-booking-mco .MuiAutocomplete-root label {
  /* margin-top: -5px !important; */
}
.addButton:disabled {
  color: #0000001f !important;
  border: 1px solid #0000001f !important;
  text-transform: capitalize !important;
}
