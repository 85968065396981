div.container a.input-group-btn {
    font-size: 14px;
}

.suggestions {
    position: relative;
    display: inline-block;
    z-index:99
}

.input-group {
    flex-wrap: nowrap;
    display: flex;
    margin-bottom: 0px;
}