.configuration-view-edit-drawer-container {
  padding: 24px;
  /* margin-top: 32px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.configuration-view-edit-drawer-container .MuiInputLabel-outlined {
  margin: 5px 0px;
}

.configuration-view-edit-drawer-if-container {
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 24px;
  margin-bottom: 24px;
}
.update-msg {
  font-size: 14px;
  color: rgba(0, 128, 0, 0.649);
  margin-left: 50px;
  font-weight: 600;
}

.configuration-view-edit-drawer-if-line {
  height: 1px;
  border-bottom: 1px solid #000000;
  opacity: 0.1;
  width: 50%;
  margin-left: 16px;
  margin-right: 16px;
}

.configuration-view-edit-drawer-input-container {
  display: flex;
  width: 100%;
  margin-top: 24px;
}

.margin-right-16 {
  margin-right: 16px;
}

.config-input-flex-1 {
  flex: 1;
}

.config-margin-top-32 {
  margin-top: 40px;
}

.add-configuration-button-container-update-new {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #a0155a;
  color: #a0155a;
  border-radius: 4px;
  margin-top: 24px;
  margin-right: 24px;
  margin-left: 24px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-configuration-button-container-update-new-button {
  cursor: pointer;
  text-align: center;
}

.edit-view-drawer-heading {
  font-family: "Cabin";
  font-weight: 600;
  font-size: 20px;
  margin-top: 24px;
  margin-left: 24px;
}

.view-configuration-drawer {
  display: flex;
  flex-direction: column;
}

.view-configuration-drawer-title {
  font-family: "Cabin";
  font-size: 12px;
  opacity: 0.6;
}

.view-configuration-drawer-description {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  margin-top: 6px;
}

.configuration-title-add-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.mt-0px {
  margin-top: 0px;
}

.configuration-title-flex-container {
  margin-top: 24px;
  font-family: "Cabin";
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
}

.ticket-drawer-container {
  margin-top: 60px;
}

.users-card-container {
  padding: 24px;
}

.user-card {
  width: 100%;
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 1px #00000014;
  padding: 16px;
  cursor: pointer;
}

.user-card-name {
  font-family: "Cabin";
  font-weight: 600;
  font-size: 16px;
}

.user-card-email {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 13px;
  margin-top: 6px;
}

.user-card-dob {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 13px;
  margin-top: 6px;
}

.user-card-email-confirmed {
  margin-top: 6px;
}

.invitation-tab {
  max-width: 38vw;
}

/* used to resize popover options */
.MuiPopover-paper {
  outline: 0;
  position: absolute;
  max-width: calc(100% - 32px);
  min-width: 16px;
  max-height: calc(100% - 200px) !important;
  min-height: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

.invalidEmail {
  color: #f44336;
  font-size: 0.75rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin: 4px 14px 0 40px;
  max-width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.holdBooking-select {
  min-width: 240px !important;
}

.res-err-msg {
  font-size: 12px;
  margin-top: 10px;
  color: red;
}

.res-succ-msg {
  font-size: 12px;
  margin-top: 10px;
  color: green;
}

.configuration-view-edit-drawer-container
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #9f155a !important;
}
