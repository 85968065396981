.sidebar-container {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
  background-color: white;
  margin-top: 10px;
}
.sidebar-head {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: flex;
  align-items: end;
}

.sidebar-heading {
  padding: 20px;
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  opacity: 0.7;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: flex;
  align-items: end;
}

.main-container {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
  background-color: white;
  margin-left: 10px;
  margin-top: 10px;
}

.permissions-box {
  padding-top: 10px;
}

.permission-action {
  margin-top: 20px;
}

.configuration-heading {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  opacity: 0.7;
  text-transform: uppercase;
}

.search-icon-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noresult-msg {
  padding: 20px;
  font-family: "Cabin";
  font-size: 14px;
  opacity: 0.7;
  text-transform: uppercase;
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
