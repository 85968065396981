.trip-type-radio-buttons {
  display: flex;
}
.trip-type-radio-button {
  margin-right: 12px;
}

.custom-font {
  font-family: "Cabin";
}
.class-type-select-width {
  max-width: 200px;
}
