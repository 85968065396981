.results-container {
  margin-top: 16px;
}
.airline-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.airline-icon {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.airline-title {
  font-family: "Cabin";
  font-size: 12px;
  /* font-weight: bold; */
  margin-left: 8px;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
  /* overflow: hidden; */
}
.br-4 {
  border-radius: 2px;
}
.airline-departure-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.airline-departure-time {
  font-family: "Cabin";
  font-weight: bold;
  font-size: 15px;
}
.airline-departure-city {
  font-family: "Cabin";
  font-weight: bold;
  font-size: 13px;
  opacity: 0.5;
  margin-top: 3px;
}
.airline-price {
  font-family: "Cabin";
  font-weight: bold;
  font-size: 22px;
}
.airline-hint-text {
  font-family: "Cabin";
  font-size: 11px;
  opacity: 0.5;
}
.airline-price-container {
  margin-right: 16px;
}
.remove-border {
  border-bottom: none !important;
}
.view-flight-details-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.view-flight-details-text {
  font-family: "Cabin";
  font-size: 12px;
  margin-bottom: 2px;
  color: #a0155a;
}
.airline-detail-expand-button {
  font-size: 8px !important;
}
.airline-detail-expand-icon-fill {
  fill: #a0155a !important;
}
.flt-dtls-expand-icon {
  fill: #a0155a !important;
  font-size: 30px !important;
}
.pointer {
  cursor: pointer;
}
.airline-select-button {
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Cabin";
  font-size: 16px;
  font-weight: bold;
}
.border-gradient {
  border: 10px solid;
  border-radius: 4px;
}
.border-gradient-purple {
  /* border-image-source: linear-gradient(96deg, #db2a5f, #a0155a); */
  /* border-color: #a0155a; */
  background-image: linear-gradient(96deg, #db2a5f, #a0155a);
  border-radius: 4px;
}
.hover-button-fill:hover {
  background-image: linear-gradient(96deg, #db2a5f, #a0155a);
  color: #ffffff;
  font-family: "Cabin";
  font-size: 16px;
  font-weight: bold;
}
.airline-select-button-container {
  width: 80%;
  display: inline-flex;
  justify-content: flex-end;
}
.airline-duration-container {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.airline-total-duration-text {
  font-family: "Cabin";
  font-size: 12px;
}
.airline-stops-text {
  font-family: "Cabin";
  font-size: 11px;
  opacity: 0.7;
}
.airline-stops-line-container {
  margin-top: 6px;
  margin-bottom: 6px;
  height: 3px;
  background: linear-gradient(96deg, #db2a5f, #a0155a);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.remove-top-padding {
  padding-top: 0px !important;
}
.airline-stop-line-dot {
  height: 3px;
  width: 3px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  cursor: pointer;
  margin-left: 3px;
  margin-right: 3px;
}
.inner-shadow {
  box-shadow: 0px 0px 2px 0px #00000024 inset;
  background: rgba(250, 250, 250, 1);
}
.airplane-details-container {
  padding: 12px;
  padding-top: 6px;
  padding-bottom: 32px;
}
.remove-bottom-padding {
  padding-bottom: 0px !important;
}
.airline-detail-header {
  display: flex;
  padding-top: 8px;
  padding-bottom: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 16px;
  align-items: center;
}
.airline-header-detail-text {
  font-family: "Cabin";
  font-size: 14px;
}
.mr-4 {
  margin-right: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-5 {
  margin-bottom: 5px;
}
.circle {
  height: 4px;
  width: 4px;
  background-color: #a0155a;
  border-radius: 50%;
}
.airline-detail-content-container {
  padding: 8px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 0px;
  display: flex;
}
.airline-detail-content-details {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
}
.airline-detail-content-amenities {
  flex: 0.4;
  display: flex;
  flex-direction: column;
}
.airline-detail-amentities {
  flex: 0.7;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.airline-details-baggage {
  flex: 0.3;
  display: flex;
  padding-left: 24px;
  padding-right: 16px;
}
.airline-details-from-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.airline-details-via-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.airline-details-to-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.airline-detail-content-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-4 {
  flex: 0.4;
}
.flex-3 {
  flex: 0.3;
}
.horizontal-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.airline-total-duration-text-light {
  font-family: "Cabin";
  font-size: 12px;
}
.airline-depart-date-time {
  font-family: "Cabin";
  font-size: 12px;
}
.bl-dashed {
  border-left: 1px dashed rgba(0, 0, 0, 0.1);
}
.airline-detail-amentities-item {
  display: flex;
  flex-direction: column;
}
.airline-detail-amentities-item-title {
  font-family: "Cabin";
  font-weight: bold;
  opacity: 0.7;
  font-size: 11px;
}
.airline-detail-amentities-item-description {
  font-family: "Cabin";
  font-weight: bold;
  font-size: 14px;
}
.opacity-5 {
  opacity: 0.5;
  margin-left: 8px;
  margin-right: 8px;
}
.opacity-6 {
  opacity: 0.6;
}
.text-bold {
  font-weight: bold;
}
.bb-dashed-1 {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}
.bt-dashed-1 {
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}
.p-4 {
  padding: 4px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pt-6 {
  padding-top: 6px;
}
.airline-details-fare-breakup {
  display: flex;
}
.airline-details-fare-title {
  flex: 0.3;
  font-family: "Cabin";
  font-size: 14px;
}
.airline-details-fare-amount {
  flex: 0.7;
  flex: 0.3;
  font-family: "Cabin";
  font-size: 14px;
}
.flex-column {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.flex-direction-column {
  flex-direction: column;
}
.pb-0 {
  padding-bottom: 0px;
}
.vertical-bottom {
  vertical-align: bottom;
}
.remove-vertical-parallel-padding {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 24px !important;
  padding-right: 8px !important;
}
.no-results-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  margin-top: 60px;
}
.no-results-text {
  margin-top: 24px;
  font-family: "Cabin";
  font-size: 14px;
  opacity: 0.7;
}
.no-results-icon {
  opacity: 0.4;
}
.cell-background {
  background: #ffffff;
}
.left-radius {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.right-radius {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pt-pb-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.pb-32 {
  padding-bottom: 32px !important;
}
.pt-32 {
  padding-top: 32px !important;
}
.br-result-item {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.flex-row-item {
  display: flex;
}
.origin-destination-texts {
  font-family: "Cabin";
  font-size: 12px;
  margin-top: 4px;
  /* margin-left: 32px; */
}
.text-bold {
  font-weight: bold;
}
.results-table {
  border-collapse: separate !important;
  border-spacing: 0px 16px !important;
}
.non-collapse {
  border-collapse: collapse;

  overflow: hidden;
}
.table-div {
  margin-bottom: 12px;
}
.table-item-row {
  margin-bottom: 12px;
}
.justify-space-between {
  display: flex;
  justify-content: space-between;
}
.width-50 {
  width: 55%;
}
.font-16 {
  font-size: 15px;
}
.width-25 {
  width: 25%;
}
.width-30 {
  width: 30%;
}
.mt-6 {
  margin-top: 6px;
}
.align-baseline {
  vertical-align: baseline !important;
}
.selected-ticket {
  border: 1px solid rgba(0, 0, 0, 1);
}
.primary-fill {
  fill: #9f155a !important;
}
.pnrTableCell {
  display: flex !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  float: right;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.pnrCell {
  /* background: #9f155a1a !important; */
  /* background: #340f2f !important; */
  /* background: #139103 !important; */
  background: radial-gradient(#9f155a, #55002a);
  margin: 0px 5px;
  padding: 5px;
  /* color: #000; */
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
