/* General Arrow Styling */
.custom-arrow {
    display: inline;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 50%; /* Round shape */
    color: #fff;
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    z-index: 1000; /* Ensure arrows are above the content */
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Positioning the Arrows */
  /* .custom-prev {
 
    left: -50px; 
  }
  
  .custom-next {
    right: -50px; 
  } */
  
  /* Hover Effect */
  /* .custom-arrow:hover {
    background: #008000; 
    color: #fff;
    transform: scale(1.1); 
    transition: 0.3s ease;
  } */
  


  .calendar-date-box {
    height: 0;
    /* padding: 10px; */
    box-sizing: border-box; /* Ensure padding is included in width/height */
  }
  
  .date-box {
    /* padding: 10px; */
    border: 1px solid #ccc; /* Add border to each box */
    border-radius: 5px; /* Optional: round the corners */
    background: #fff;
    text-align: center;
  }
  
  .date-box.selected {
    border: 2px solid #007bff; /* Highlight for selected item */
    background: #e6f7ff;
  }
  
  .day {
    font-size: 14px;
    color: #333;
    margin-bottom: 3px;
  }
  
  .prices {
    font-size: 16px;
    margin-bottom: 3px;
    font-weight: bold;
  }


  .calendar-date-box {
    height: auto !important;
  }


  .slider-container{
    width: calc(100% - 50px); 
  }

  .carousel-wrapper {
    display: flex;
  }


  .grey {
    color: grey;
  }
  
  .red {
    color: red;
  }
  
  .green {
    color: green;
  }
  
  .black {
    color: black;
  }

  .prices.grey {
    color: grey;
  }
  .prices.black {
    color: black;
  }
  .prices.red {
    color: red;
  }
  .prices.green {
    color: green;
  }
  
  



  