.megamenu-container {
  min-width: calc(100vw - 20px);
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 85vh;
}
.administration-block-container {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.administration-title {
  font-family: "Cabin";
  font-size: 16px;
}
.administration-drop-icon {
  height: 24px;
  width: 24px;
  margin-left: 8px;
  align-self: center;
  cursor: pointer;
  opacity: 0.5;
}
.popover-admin-container {
  background-color: #ffffff;
  min-width: calc(100vw - 20px);
}
.admin-menu-item {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
}
.admin-menu-item:hover {
  background-color: rgba(159, 21, 90, 0.1);
  border-radius: 8px;
}
/* .admin-item-border-right:not(:nth-child(4n)) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
} */
.admin-pointer-none {
  pointer-events: none;
}
.header-user-details-container {
  display: flex;
  margin-top: -8px;
  margin-bottom: -8px;
  margin-left: 24px;
  margin-right: 24px;
  cursor: pointer;
}
.header-user-details-avatar-container {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 8px;
}
.header-user-details-detailed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
}
.header-user-details-username {
  font-family: "Cabin";
  font-size: 14px;
}
.header-user-details-user-role {
  font-family: "Cabin";
  font-size: 11px;
  margin-top: 3px;
  opacity: 0.5;
}
.popover-profile-container {
  background-color: #ffffff;
}
.popover-profile-container-item {
  padding: 16px;
  cursor: pointer;
}
.header-nav-item-container {
  text-align: center;
  margin: 0px 25px;
  cursor: pointer;
}
.nav-item-icon {
  fill: #a0155a !important;
}
.nav-item-text {  
  font-family: "Cabin";
  font-size: 15px;
  color: #a0155a;
  margin-top: 4px;
  line-height: 1.2;
}
.header-nav-items-container {
  display: flex;
}
.f-12p{
  font-size: 12px !important;
}
.coming-soon {
  font-size: 10px; 
  position: absolute;
  bottom: 1%;
  text-shadow: 2px 2px 8px #a0155a;
}

.menu_row {
  padding: 1%;
}

.menu_sub_header {
  color: #340f34;
  padding: 2%;
  border-bottom: 1px dotted;
}

.menu_item {
  color: #000;
  text-decoration: none;
  display: block;
  cursor: pointer;
  padding: 4% 2%;
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
}

.menu_item:hover {
  background: #340f34; 
  color: #ffffff;
  opacity: 1;
  border-radius: 5px;
  transform: scale(1.1,1.1);
}

.balance-main-heading{
  font-size: 13px;
  font-weight: 600;
}