.full-width {
	width: 100%;
}
.search-bar-container {
	background-color: #ffffff;
	box-shadow: 0px 0px 12px 0px #00000024;
	border-radius: 8px;
	padding-top: 24px;
	padding-bottom: 60px;
	padding-left: 24px;
	padding-right: 24px;
	position: relative;
}
.search-button {
	position: absolute;
	height: 36px;
	bottom: -18px;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.modify-search-button {
	height: 36px;
	margin-top: 32px;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 2rem;
}
.pb-32 {
	padding-bottom: 32px !important;
}
.search-block {
	cursor: pointer;
	padding: 8px;
	border-radius: 4px;
	height: 50px;
	width: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	letter-spacing: 1px;
	color: #ffffff;
	font-family: "Cabin";
	text-transform: uppercase;
	font-weight: bold;
	background-image: linear-gradient(96deg, #db2a5f, #a0155a);
}

.itienary-search-block {
	padding: 8px !important;
	border-radius: 4px !important;
	color: #db2a5f !important;
	border-color: #db2a5f !important;
	text-transform: capitalize !important;
}
.preference-container {
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.basic-search-bar-container {
	margin-top: 16px;
	margin-bottom: 24px;
}
.disable-search-button {
	pointer-events: none;
	background-color: darkgrey;
	background-image: none;
}
.mb-3 {
	margin-bottom: 3px;
}
.pr-12 {
	padding-right: 12px;
}
.horizontal-flex {
	display: flex;
	justify-content: space-between;
}
.pax-dropdown {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0px;
}
.pax-icon-fill {
	fill: rgba(0, 0, 0, 0.54) !important;
}
.pax-text {
	font-size: 16px;
	font-family: "Cabin";
}
.pax-icon {
	margin-top: 4px;
}
.pointer {
	cursor: pointer;
}
.ml-24 {
	margin-left: 24px;
}
.flex-display {
	display: flex !important;
	align-items: center !important;
}
.airlineSelect .MuiFilledInput-underline:before {
	border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}
.direct-flight-toggle .MuiFormControlLabel-labelPlacementStart {
	margin-left: 0px !important;
	margin-right: 0px !important;
	flex-direction: row !important;
}
