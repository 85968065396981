.item {
    margin: 1em auto;
    display: flex;
    list-style-type: none;
}

.pager {
    margin: auto;
    max-width: fit-content;
}

.page-item {
    padding: 10px;
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: #fff;
    background-color: #9f155a;
    border-color: #9f155a;
}

.active>.page-link,
.page-link.activ .MuiButton-root.Mui-disabled {
    color: #fff !important;
}

.disabled>.page-link,
.page-link.disabled .MuiButton-root.Mui-disabled {
    color: #9f155a !important;
    border-color: #9f155a !important;
    pointer-events: none !important;
}

.pager .MuiButton-outlined:hover {
    color: #9f155a !important;
    border-color: #9f155a !important;
}