.invalid {
  color: #f44336;
  margin-right: 85px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  position: relative;
  float: right;
}
.searchSticky {
  position: sticky;
  top: 8%;
  z-index: 100;
}
.tabSticky {
  position: sticky;
  z-index: 100;
  top: 1%;
}
.FilterContainerSticky {
  /* max-height: 70vh;
  opacity: 1 !important;
  overflow-y: scroll !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 27% !important;
  z-index: 1 !important;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  max-height: 70vh;
  margin-top: 1vh !important;
  opacity: 1 !important;
  overflow-y: auto !important;
  position: sticky !important;
  top: 27% !important;
  z-index: 50 !important;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px 0px 0px 25px;
}
.filterHeadSticky {
  position: sticky;
  z-index: 50;
  top: 0;
  width: calc(100% + 10px);
}

.exportSticky {
  opacity: 1 !important;
  position: sticky !important;
  top: 26% !important;
  z-index: 10 !important;
  background-color: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.sidebar-container1 {
  padding: 0 10px;
  border-radius: 10px;
}

.search-sidebar-heading {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: flex;
  float: left;
}
.filter-sidebar-heading {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 5px;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; */
  background: #fff;
}

.sidebar-heading-right {
  float: right;
}

.main-container1 {
  margin-left: 10px;
  margin-top: 10px;
}

.permissions-box {
  padding-top: 10px;
}

.permission-action {
  margin-top: 20px;
}

.configuration-heading {
  font-family: "Cabin";
  font-weight: 500;
  font-size: 14px;
  opacity: 0.7;
}

.search-icon-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sui-layout-header {
  background-color: #0078d7;
  color: #eee;
}

.sui-search-box__submit {
  background: linear-gradient(rgb(60, 226, 102), rgb(34, 151, 57));
  letter-spacing: 0.1em;
}

.sui-search-box__submit:hover {
  background: linear-gradient(rgb(34, 151, 57), rgb(60, 226, 102));
}

.pager-style {
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;
}

.search-bar {
  margin: 1em;
  margin-bottom: 1em;
  margin-top: 2em;
}

@media (min-width: 960px) {
  .MuiGrid-grid-md-9 {
    flex-grow: 0;
    max-width: 73% !important;
    flex-basis: 73% !important;
  }
}

.dbcount-css-diff {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  text-decoration: underline;
  text-transform: uppercase;
  color: red;
  margin-bottom: 5px;
  display: flex;
  float: left;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.dbcount-css-same {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  text-decoration: underline;
  text-transform: uppercase;
  color: #9f155a;
  margin-bottom: 5px;
  display: flex;
  float: left;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
