.trans-heading {
  color: gray;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.trans-client-container {
  display: flex;
  justify-content: center;
}

.trans-data {
  margin-top: 30px;
}

.trans-client-container-search-button {
  background-color: #9f155a !important;
  color: white !important;
  padding: 15px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  cursor: pointer !important;
  display: flex !important;
  border: 0px;
}

.trans-client-container-search-button:disabled {
  background-color: #ddd !important;
  color: #00000052 !important;
}

.trans-client-container-export-button {
  background-color: #9f155a !important;
  color: white !important;
  padding: 15px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  cursor: pointer !important;
  display: flex !important;
  border-left: 5px solid #fff;
}

.trans-client-container-export-button.disabled {
  background-color: #b7afaf !important;
  color: #472a2a !important;
  cursor: not-allowed !important;
}

.trans-client-container-item2 {
  background-color: white;
  border: 1px solid #d4d4d4;
}

.trans-client-container-label {
  background-color: gainsboro;
  padding: 15px;
  border: 1px solid #d4d4d4;
}

.trans-client-container-labelLeft {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.trans-client-balance {
  display: flex;
  border-radius: 10px;
  justify-content: end;
  margin-bottom: 10px;
}

.trans-client-balance-label {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: gainsboro;
  padding: 15px;
}

.trans-client-balance-Amount {
  background-color: #9f155a;
  color: white;
  padding: 15px;
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
  display: flex;
}

.trans-head-container {
  margin-top: 10px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

/* .debit-transaction-panel .MuiFormControl-fullWidth{
    margin-top: 8px;
} */
.debit-transaction-panel .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.invalidDate {
  font-size: 14px;
  color: red;
  font-weight: 500;
  margin: 10px 0px;
  text-align: center;
}