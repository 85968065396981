.page-break-inside {
  page-break-inside: avoid; 
  break-inside: avoid;
}

.eticket-container {
  /* width: 70vw; */
  background-color: #ffffff;
  margin: 0 auto !important;
}

.eticket-header {
  /* height: 100px;
  background-color: #9f155a; */
  /* width: 70vw; */
  padding: 0px 1vh;
  position: relative;
}

.eticket-header-table {
  /* background: linear-gradient(210deg, #9f155a, #ffffff 110%); */
  /* background: linear-gradient(90deg, #9f155a, #55002a 100%); */
  /* background: linear-gradient(90deg, #9f155a, #ffffff 150%); */
  background: radial-gradient(#9f155a, #75043c);
}

.eticket-header-table td {
  padding: 5px;
  color: #fff !important;
}

.eticket-title-container {
  height: 150px;
  /* background-color: #db2a5f; */
  width: 300px;
  position: absolute;
  right: 80px;
  top: 0px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.eticket-title {
  /* padding: 32px;
  font-size: 40px;
  color: white;
  font-family: "Cabin";
  text-transform: uppercase;
  font-weight: bolder;
  text-align: end; */
  padding: 2vh;
  font-size: 30px;
  color: white;
  font-family: "Cabin";
  text-transform: uppercase;
  font-weight: bolder;
  text-align: end;
}

.eticket-details-container {
  /* position: absolute;
  right: 80px;
  top: 174px;
  width: 300px; */
  padding: 0px 1vh;
}

.eticket-detail {
  /* display: flex;
  margin-top: 10px;
  opacity: 0.7; */
  display: flex;
  margin-top: 10px;
  opacity: 0.7;
  text-align: end;
  padding-right: 1vh;
}

.eticket-item {
  flex: 1;
  font-family: "Cabin";
}

.eticket-separator {
  flex: 0.3;
}

.eticket-org-details-container {
  display: flex;
  position: absolute;
  right: 380px;
  top: 110px;
  left: 0;
  /* justify-content: space-around; */
  font-size: 15px;
  opacity: 0.7;
  font-family: "Cabin";
}

.org-phone {
  margin-bottom: 8px;
}

.org-address-1 {
  margin-bottom: 8px;
}

.org-communication-container {
  flex: 1;
  margin-left: 80px;
}

.org-address-container {
  flex: 1;
}

.eticket-to-container {
  margin-top: 10px;
  font-weight: bold;
}

.eticket-body {
  padding: 1vh;
  margin-bottom: 100px !important;
  padding-top: 0px;
}

.eticket-to-item {
  margin-bottom: 8px;
}

.eticket-to-heading {
  text-transform: uppercase;
  font-family: "CABIN";
  font-weight: bold;
  font-size: 20px;
  opacity: 0.3;
  margin-bottom: 16px;
}

.eticket-to-person {
  font-size: 22px;
  font-weight: bold;
  color: #9f155a;
  margin-bottom: 8px;
}

.eticket-to-phone {
  margin-top: 24px;
  opacity: 0.7;
  font-family: "Cabin";
}

.eticket-to-email {
  opacity: 0.7;
  font-family: "Cabin";
}

.eticket-to-add1 {
  margin-top: 24px;
  opacity: 0.7;
  font-family: "Cabin";
}

.eticket-to-add2 {
  opacity: 0.7;
  font-family: "Cabin";
}

.eticket-passenger-table-header {
  height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.eticket-header-item {
  background-color: #9f155a1a !important;
  color: #9f155a !important;
  opacity: 1 !important;
}

.eticket-passenger-table-item {
  background-color: #fafafa !important;
}

.eticket-header-item-font-weight {
  font-weight: 600 !important;
}

.eticket-fare-detail-container {
  background-color: #fafafa !important;
}

.eticket-contact-details-container {
  background-color: #fafafa !important;
}

.eticket-more-detail-item-icon {
  background-color: #fafafa !important;
}

.eticket-itenary-container {
  font-size: 24px;
  padding-bottom: 16px;
}

.eticket-logo-container {
  /* padding-top: 23px;
  padding-left: 75px; */
  padding: 2vh 2vh;
  /* background: repeating-linear-gradient(45deg, black, transparent 100px); */
}

.eticket-booking-padding-top-40 {
  padding-top: 64px;
}

.baggage-details {
  width: 100%;
  margin-top: 24px;
}

.baggage-details-container-Eticket {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 10px 0px;
  margin: auto;
  display: flex;
  margin-top: 2px;
  text-align: center !important;
}

.baggage-detail-flex {
  flex: 1;
  display: flex;
}

.baggage-detail-item {
  display: flex;
  align-items: center;
}

.baggage-detail-icon {
  margin-right: 12px;
  margin-left: 12px;
}

.baggage-detail-text {
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
}

.baggage-detail-opacity {
  opacity: 0.5;
}

.one-liner {
  min-width: 100px !important;
}

.eticket-baggage-price-label {
  font-size: 14px !important;
  font-weight: 600;
  padding: 0px 15px;
  background-color: #fff;
}

.eticket-baggage-price-txt {
  padding: 0px 15px;
  font-size: 14px !important;
  font-weight: 600;
  background-color: #fff;
  width: 20% !important;
}

.eticket-total-price-label {
  font-size: 16px !important;
  font-weight: 800;
  padding: 0px 15px;
  background-color: #fff;
}

.eticket-total-price-txt {
  padding: 0px 15px;
  font-size: 16px !important;
  font-weight: 800;
  background-color: #fff;
  width: 211px !important;
}

.eticket-container.itenary-passenger-table-h-64 {
  height: 48px;
  line-height: 4.7vh;
  color: #2c2c2ce8;
  padding: 5px !important;
}

.eticket-container.itenary-passenger-table {
  width: 100%;
  border-spacing: 1px;
  text-align: center !important;
}

.baggage-details-container-Eticket .col-1 {
  padding: 0px !important;
}

.baggage-details-container-Eticket .col-2 {
  padding: 0px !important;
}

.baggage-details-container-Eticket .col-3 {
  padding: 0px !important;
}

.baggage-details-container-Eticket .col-4 {
  padding: 0px !important;
}

.pdficon {
  text-transform: capitalize;
  color: #f7f7f7;
  font-size: 70px !important;
  position: fixed;
  background: #340f2f;
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
}

.pdficonContainer {
  position: absolute;
  right: 1%;
  bottom: 30%;
  min-width: 100px;
}

.hoverTextContainer {
  position: absolute;
  right: 4%;
  bottom: 35%;
  min-width: 100px;
}

.hoverText {
  text-transform: capitalize;
  color: #f7f7f7;
  font-size: 14px !important;
  position: fixed;
  background: #340f2f;
  border-radius: 50px;
  padding: 5px;
}

.clientDetailsTable td {
  padding: 5px !important;
}

.eTicketTicketed {
  color: #20df09;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketRefunded {
  color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketExchanged {
  color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketPayment_Success {
  color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketBooked {
  color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketBooking_Cancelled {
  color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketBooking_Failed {
  color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketTicket_Failed {
  color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketPayment_Failed {
  color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketBooking_Cancellation_Failed {
  color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketVoid_Failed {
  color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketRefund_Failed {
  color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketBooking_On_Hold {
  color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketVoided {
  color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketBooking_On_Hold {
  color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketBooking_On_Hold {
  color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketTicketing_In_Process {
  color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketExchange_In_Process {
  color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketRefund_In_Process {
  color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketVoid_In_Process {
  color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketPayment_In_Process {
  color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketBooking_In_Process {
  color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  display: inline-block;
}

.eTicketTicketing_In_Process {
  color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  display: inline-block;
}
.eticketFlightSection {
  padding-top: 10px !important;
}
eticket-.itenary-passenger-details {
  width: 100%;
  margin-top: 5px;
  padding: 0px 10px;
}

.eticket-flt-bkg-information-panel .flt-ic {
  width: 25px;
  height: 25px;
}

.eticket-flt-bkg-information-panel .flt-name {
  font-size: 13px;
}

.eticket-flt-bkg-information-panel .flt-number,
.eticket-flt-bkg-information-panel .flt-route-cls {
  font-size: 12px;
  color: #999999;
}

.eticket-flt-bkg-information-panel .flt-operated-by {
  font-size: 10px;
  color: #999999;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.25em;
}

.eticket-flt-bkg-information-panel .flt-departure-info {
  text-align: right;
  line-height: 16px;
  letter-spacing: 1px;
  font-size: 22px !important;
}

.eticket-flt-bkg-information-panel .flt-arrival-info {
  text-align: left;
  line-height: 16px;
  letter-spacing: 1px;
  font-size: 22px !important;
}

.eticket-flt-bkg-information-panel .flt-departure-info .flt-origin {
  padding-right: 5px;
}

.eticket-flt-bkg-information-panel .flt-arrival-info .flt-destination {
  padding-left: 5px;
}

.eticket-flt-bkg-information-panel .flt-departure-info .flt-airport-code,
.eticket-flt-bkg-information-panel .flt-arrival-info .flt-airport-code {
  font-size: 18px;
}

.eticket-flt-bkg-information-panel .flt-departure-info .flt-time,
.eticket-flt-bkg-information-panel .flt-arrival-info .flt-time {
  font-size: 17px;
  font-weight: bold;
}

.eticket-flt-bkg-information-panel .flt-departure-info .flt-date,
.eticket-flt-bkg-information-panel .flt-arrival-info .flt-date {
  font-size: 14px;
}

.eticket-flt-bkg-information-panel .flt-departure-info .flt-airport-nm,
.eticket-flt-bkg-information-panel .flt-arrival-info .flt-airport-nm {
  font-size: 12px;
  color: #999999;
}

.eticket-flt-bkg-information-panel .flt-duration-info .flt-duration {
  font-size: 12px;
  color: #999999;
}
