body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.MuiSvgIcon-colorPrimary {
	color: #9f155a !important;
}

.MuiInput-underline:before {
	border: none !important;
}

.MuiInput-underline:after {
	border: none !important;
}

.MuiRadio-colorPrimary.Mui-checked {
	color: #9f155a !important;
}

.MuiTypography-body1 {
	font-family: "Cabin" !important;
	font-weight: 400 !important;
	font-size: 16px !important;
}

.MuiToggleButton-root.Mui-selected {
	color: #ffffff;
}

.MuiToggleButton-root.Mui-selected {
	color: #ffffff !important;
	background-color: #9f155a !important;
	box-shadow: 0px 0px 12px 0px #00000033;
}

.p-8 {
	padding: 8px;
}

.pl-5 {
	padding: 5px !important;
}

.pl-16 {
	padding-left: 16px;
}

.pl-24 {
	padding-left: 24px;
}

.bold {
	font-weight: bold !important;
}

.small-font {
	font-size: 14px;
}

.medium-opacity {
	opacity: 0.4;
}

.br-1 {
	border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.full-height {
	height: 100%;
}

.mb-8 {
	margin-bottom: 8px;
}

.icon-blue {
	fill: #9f155a !important;
}

.hover-fill:hover {
	background-color: rgba(0, 82, 204, 0.05);
	border-radius: 2px;
}

.max-width {
	max-width: 100vw;
}

.min-width {
	min-width: 100vw;
}

.MuiToggleButton-root {
	box-shadow: 0px 0px 3px #00000029;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	background-color: #f0f8ff !important;
	color: #9f155a !important;
}

.Selectable .DayPicker-Day {
	border-radius: 4px !important;
}

.Selectable .DayPicker-Day--start {
	border-top-left-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
}

.Selectable .DayPicker-Day--end {
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
	background-color: #9f155a !important;
}

.DayPicker-Day {
	padding-left: 14px !important;
	padding-right: 14px !important;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}

.DayPicker-Weekday {
	padding: 14px !important;
}

.DayPicker-Caption>div {
	text-align: center;
	font-size: 16px !important;
}

.DayPicker-NavBar {
	position: absolute;
	left: 0;
	right: 0;
}

.DayPicker-Day {
	border-radius: 4px !important;
}

.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	background-color: #f5e8ef !important;
	color: #a92c6b !important;
}

.MuiDialog-paperWidthSm {
	max-width: 100vw !important;
}

.MuiDialog-paper {
	border-radius: 8px !important;
}

.PrivateSwitchBase-root-2 {
	padding: 6px !important;
}

.PrivateSwitchBase-root-1 {
	padding: 6px !important;
}

.MuiFormControlLabel-label {
	font-size: 14px !important;
}

.MuiSvgIcon-root {
	width: 20px;
	height: 20px;
}

.MuiCheckbox-root {
	color: rgba(0, 0, 0, 0.2);
}

.MuiTableCell-root {
	font-family: "Cabin" !important;
	border-bottom: 0px !important;
}

.MuiTableCell-head {
	font-weight: bold !important;
	font-size: 13px !important;
	opacity: 0.5;
}

.MuiSwitch-root {
	height: 34px !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
	color: #a92c6b !important;
}

.MuiStepper-root {
	background-color: rgba(0, 0, 0, 0) !important;
	padding: 4px !important;
}

.flex {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
}

.MuiStepIcon-root.MuiStepIcon-active {
	color: #db2a5f !important;
}

.MuiStepLabel-label {
	color: rgba(255, 255, 255, 0.7) !important;
	font-family: "Cabin" !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
	color: rgba(255, 255, 255, 1) !important;
	font-weight: 500;
	font-family: "Cabin" !important;
}

.MuiInputBase-input {
	font-family: "Cabin" !important;
}

.MuiFilledInput-root {
	background-color: #fff !important;
}

.MuiFormControl-root {
	width: 100%;
}

.MuiStepIcon-root.MuiStepIcon-completed {
	color: #db2a5f !important;
}

.MuiList-root {
	min-width: 160px;
}

.MuiMenuItem-root {
	font-family: "Cabin" !important;
	font-size: 14px !important;
}

.MuiTableContainer-root {
	box-shadow: none !important;
}

.MuiTable-root {
	/* border-collapse: initial !important; */
	border-spacing: 0px 12px !important;
}

.MuiDrawer-paperAnchorDockedRight {
	width: auto !important;
	box-shadow: 0px 0px 5px 0px #00000036 !important;
	border-left: none !important;
}

.top-z-index {
	z-index: 2000;
}

.DayPicker-Day--disabled {
	pointer-events: none;
}

.MuiOutlinedInput-notchedOutline {
	border-color: rgba(0, 0, 0, 0.1) !important;
}

.MuiOutlinedInput-root {
	height: 40px;
}

.MuiInputLabel-outlined {
	transform: translate(14px, 14px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(14px, -6px) scale(0.75) !important;
}

.MuiFormControl-root {
	height: auto;
}

.drawer-min-width-40 {
	min-width: 45vw;
}

.MuiPaper-elevation8 {
	box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 9%), 0px 8px 10px 1px rgb(0 0 0 / 8%),
		0px 3px 14px 2px rgb(0 0 0 / 0%) !important;
}

.MuiFormHelperText-marginDense {
	margin-top: 0px;
}

.MuiChip-root {
	height: 24px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
	padding-right: 65px;
	height: min-content;
}

.padR5 {
	padding-right: 5px !important;
}

.padL5 {
	padding-left: 5px !important;
}

.padT5 {
	padding-top: 5px;
}

.padB5 {
	padding-bottom: 5px;
}

.padTB5 {
	padding: 5px 0;
}

.padB8 {
	padding-bottom: 8px;
}

.padTB8 {
	padding: 8px 0;
}

.pad0 {
	padding: 0px !important;
}

.bdrbBlack {
	border-bottom: 1px #000 dotted !important;
}

.marginLR10 {
	margin: 0px 10px !important;
}

.fs24 {
	font-size: 1.5em !important;
}

.fw300 {
	font-weight: 300 !important;
}