.docUpload-mainDiv {
  margin: 0 100px 0 100px;
  background-color: white;
  border-radius: 14px !important;
  box-shadow: 0 0 5px 3px #0000000a !important;
  padding: 20px;
}

.docUpload-heading {
  margin: 50px 100px 20px 100px;
  font-size: 30px;
  font-weight: 500;
}

.upload-doc-lbl {
  cursor: pointer;
  /* color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)); */
  color: #9f155a;
  text-decoration: underline;
}
