.table-cell-title {
    /* font-weight: 500; */
    font-family: "Cabin" !important;
    opacity: 0.7 !important;
    color: #000000 !important;
  }
  .table-cell-description {
    font-family: "Cabin" !important;
    opacity: 0.4 !important;
    font-size: 12px;
    color: #000000 !important;
    margin-top: 2px;
  }
  .table-background {
    background-color: #ffffff !important;
  }
  .table-paper-styles {
    border-radius: 14px !important;
    background: #ffffff;
    box-shadow: 0px 0px 5px 3px #0000000a !important;
  }
  .user-card-name {
    font-family: "Cabin";
    font-weight: 600;
    font-size: 16px;
  }
  .user-card-email {
    font-family: "Cabin";
    font-weight: 300;
    font-size: 13px;
    margin-top: 6px;
  }
  .user-card-dob {
    font-family: "Cabin";
    font-weight: 300;
    font-size: 13px;
    margin-top: 6px;
  }
  .user-card-email-confirmed {
    margin-top: 6px;
  }

  .searchText{
    font-family: "Cabin";
          font-weight: 600;
          font-size: 16px;
          text-align: center;
  }
  