.reissue-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Reissuefilter1stRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.reissue-date-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 16px; */
}

.reissue-container .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.copy-ressue-itenary-header {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 25px !important;
  color: #340f2f;
  margin-top: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit-copy-segment {
  margin-top: 4vw;
  width: 50vw;
}

.reissue-supplierfare,
.reissue-clientfare {
  width: 100%;
}

.reissue-supplierfare .MuiTable-root,
.reissue-clientfare .MuiTable-root {
  /* border-collapse: initial !important; */
  border-spacing: 0px 0px !important;
}

.refsubmit-btn {
  background: #340f2f !important;
  color: #ffffff !important;
  font-size: 14px !important;
  /* margin-left: 900px !important; */
}

.disabled-btn {
  opacity: 0.1;
  cursor: not-allowed;
  background-color: #dcdcdc;
  color: #888888;
  border: 1px solid #cccccc;
}

.form-bottom-details {
  font-size: 15px;
  font-weight: 600;
  color: #291f1fcc;
}
