.search-results-page-container {
  width: 100vw;
  /* height: 100vh; */
  position: relative;
  background-color: #f2f2f2;
}
.custom-loader {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin-top: -120px; */
}
.loading-text {
  font-family: "Cabin";
  font-size: 16px;
  color: #9f155a;
  font-weight: bold;
  /* margin-top: -60px; */
}
.search-results-container {
  padding-bottom: 120px;
  /* height: 100%; */
}
.search-results-header {
  padding: 24px;
  /* min-height: 320px; */
  background: linear-gradient(to bottom, #26173d, #340f2f);
}
.logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}
.modify-search-container {
  margin-top: 32px;
  width: 100%;
}
.logo-small {
  height: 48px;
}
.full-width {
  width: 100vw;
}
.negative-margin-top-120 {
  margin-top: 40px;
}
.search-results-title {
  font-size: 24px;
  font-family: "Cabin";
  font-weight: bold;
  /* color: #ffffff; */
}
.opacity-5 {
  opacity: 0.5;
}
.small-font {
  font-size: 16px;
}
.ml-16 {
  margin-left: 16px !important;
}
.booking-selection-container {
  z-index: 2;
  height: 90px;
  position: fixed;
  bottom: 8px;
  right: 9%;
  left: 31%;
  background-image: linear-gradient(to bottom, #26173d, #340f2f);
  box-shadow: 0px -2px 9px 0px #0000004d;
  border-radius: 4px;
}
.set-custom-bottom {
  bottom: 270px !important;
}
.display-none {
  display: none !important;
}
.no-results-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  margin-top: 60px;
}
.no-results-text {
  margin-top: 24px;
  font-family: "Cabin";
  font-size: 14px;
  opacity: 0.7;
}
.no-results-icon {
  opacity: 0.4;
}
.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

.confirm-booking-heading-container {
  display: flex;
  flex-direction: column;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.drawer-width {
  flex-shrink: 0;
}
.skeleton-loader-styles {
  margin-top: 48px;
  margin-bottom: 32px;
}
.ml-40 {
  margin-left: 40px;
}
.skeleton-margin-top {
  margin-top: 16px;
}
.mt-32 {
  margin-top: 32px;
}
.skeleton-type-second {
  flex-grow: 1;
  margin-right: 8px;
}
