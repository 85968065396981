.footer-container {
  background: #302A2A !important;
  padding: 2.5em 16px !important;
}

.footer-content {
  display: flex;
  flex-direction: column;
}

.footer-content-item {
  margin-top: 12px;
  margin-bottom: 12px;

}

.footer-content-item .text-link:hover {
  color: rgb(216, 42, 97);
}

.footer-item-heading {
  font-family: "Montserrat";
  font-weight: bold;
  color: #fff;
}

.footer-item-content-text {
  opacity: 0.6;
  color: white;
}

.separator {
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 32px;
  margin-bottom: 24px;
}

.footer-bottom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e7e4e5;
}

.footer-left-container {
  display: flex;
  align-items: center;
}

.footer-left-container-text {
  margin-left: 8px;
  font-size: 13px;
  opacity: 0.6;
  font-family: "Cabin";
}

.footer-right-container {
  display: flex;
  align-items: center;
}

.footer-right-container-text {
  margin-left: 32px;
  font-size: 13px;
  opacity: 0.6;
  font-family: "Cabin";
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.footer-container .footer-logo {
  height: 2.8rem;
}

@media (max-width: 600px) {
  .footer-container .footer-logo {
    height: 2rem;
  }
}

.footer-content .footer-logo-container { 
  background: white; 
  border-radius: 0px 50px 50px 0px;
  padding: 1em;
  width: 87%;
  text-align: center;
}