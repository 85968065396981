.search-filters-container {
	background-color: #ffffff !important;
	border-radius: 4px !important;
	box-shadow: 0 0 5px 1px #00000030 !important;
	padding: 5px 10px !important;
	position: sticky !important;
	top: 20% !important;
	opacity: 1 !important;
	z-index: 1 !important;
	min-height: calc(100vh - 160px) !important;
	max-height: calc(100vh - 160px) !important;
	overflow-y: scroll !important;
	margin: 10px 0px !important;
}

.filter-item-container {
	display: flex;
	flex-direction: column;
	margin-top: 12px;
	margin-bottom: 12px;
	width: 100%;
}

.filter-item-title {
	font-family: "Cabin";
	font-size: 16px;
	font-weight: bold;
}

.mt-24 {
	margin-top: 24px;
}

.ml-8 {
	margin-left: 8px;
}

.ml-3 {
	margin-left: 3px;
}

.mt-32 {
	margin-top: 32px;
}

.mr-8 {
	margin-right: 8px;
}
.defaultButton {
	color: var(--bs-pink) !important;
	border-color: var(--bs-pink) !important;
	text-transform: capitalize !important;
	float: inline-end !important;
	max-width: 10vw !important;
}
.defaultButton:hover {
	background-color: var(--bs-pink) !important;
	background: var(--bs-pink) !important;
	border-color: var(--bs-pink) !important;
	color: #ffffff !important;
	text-transform: capitalize !important;
}
