.impsAccountInfotable {
  border: 0.5px solid #eee;
  background: #eee;
  padding: 1vw;
}
.impsPaymentTitleContainer {
  border-bottom: 1px #eee;
  font-family: "Cabin";
  /* font-weight: 600; */
  font-size: 14px;
  margin: 1vh auto;
  margin-top: 10px;
  padding: 0 10px;
  max-width: 50vw;
  background: #fff;
  padding-bottom: 1vh;
}
.impsPaymentTitle {
  font-family: "Cabin";
  font-weight: bold;
  font-size: 14px;
  opacity: 1;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #b1b1b1;
  padding: 10px;
}
.impsPaymentHeading {
  padding-left: 1vw;
}
.impsSteps {
  margin-bottom: 24px;
}

.impsStepsHead {
  font-family: "Cabin";
  font-weight: bold;
  font-size: 14px;
  opacity: 1;
  text-transform: uppercase;
  margin: 15px 0;
}
.impsInfo {
  background: #ff000059;
  text-transform: capitalize;
  border-radius: 100px;
  padding: 5px;
  margin-bottom: 10px;
}

.impsInfo p {
  padding: 0px 10px;
  margin: 0px;
}
