.payment-form {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 20px;
  box-sizing: border-box;
}

.payment-form .form-group {
  margin-bottom: 15px;
}

.payment-form .input-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.payment-form .input-icon {
  position: absolute;
  right: 10px;
  padding-top: 12px;
  display: flex;
  align-items: center;
}

.payment-form .expiry-date-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.payment-form .d-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.payment-form .d-flex > div {
  flex: 1;
  min-width: 300px;
}

@media (max-width: 768px) {
  .input-icon-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .d-flex {
    flex-direction: column;
  }

  .d-flex > div {
    width: 100%;
  }

  .expiry-date-wrapper {
    flex-direction: column;
  }

  .expiry-date-wrapper > div {
    width: 100%;
  }
}

.exp-label {
  font-size: 12px;
  color: grey;
  font-weight: 500;
}

.payment-form .MuiFilledInput-underline:before,
.payment-form .MuiFilledInput-underline:after {
  border-bottom: none !important;
}

.payment-form .css-1j0qgw8-MuiAutocomplete-root .MuiFilledInput-root {
  padding-top: 8px;
  padding-left: 8px;
}
