.invalid
{
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.UpdatePasswordContainer {
  min-width: 35%;
  min-height: 50%;

  box-shadow: 1px 2px 2px #073b4c;
  border-radius: 2em;
  border: 1px solid #073b4c;
  background-color: #ffd166;
}

.must-container {
  margin-bottom: 1em;
  border: 0.1em solid #cbcccc;
  padding: 15px;
  border-radius: 1.2em;
}

.must-item {
  position: relative;

  width: 15em;
  height: 1em;

  padding: 0.7em 0;
}

.must-text {
  position: absolute;
  z-index: 0;
  top: 0.6em;
  left: 1em;

  width: 100%;

  padding: 0;
  margin: 0;
  line-height: 0;
}

.must-line {
  position: absolute;
  z-index: 1;
  top: 9px;
  width: 0;
  height: 0.1em;
  background-color: #9f155a;
  transition: 0.7s;
}

.cross-out {
  width: 100%;
}

/* misc styling for form elements */
/* label { */
  /* padding-bottom: 1em; */
  /* font-size: 0.8em; */
/* } */

input {
  padding: 0.4em 0.5em;
}

h4 {
  padding-top: 1em;
  line-height: 0;
}

.configuration-view-edit-drawer-container {
  padding: 24px;
  /* margin-top: 32px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.configuration-view-edit-drawer-if-container {
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 24px;
  margin-bottom: 24px;
}
.configuration-view-edit-drawer-if-line {
  height: 1px;
  border-bottom: 1px solid #000000;
  opacity: 0.1;
  width: 50%;
  margin-left: 16px;
  margin-right: 16px;
}
.configuration-view-edit-drawer-input-container {
  display: flex;
  width: 100%;
  margin-top: 24px;
}
.margin-right-16 {
  margin-right: 16px;
}
.config-input-flex-1 {
  flex: 1;
}
.config-margin-top-32 {
  margin-top: 40px;
}
.add-configuration-button-container-update-new {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #a0155a;
  color: #a0155a;
  border-radius: 4px;
  margin-top: 24px;
  margin-right: 24px;
  margin-left: 24px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-configuration-button-container-update-new-button {
  cursor: pointer;
  text-align: center;
}
.edit-view-drawer-heading {
  font-family: "Cabin";
  font-weight: 600;
  font-size: 20px;
  margin-top: 24px;
  margin-left: 24px;
}
.view-configuration-drawer {
  display: flex;
  flex-direction: column;
}
.view-configuration-drawer-title {
  font-family: "Cabin";
  font-size: 12px;
  opacity: 0.6;
}
.view-configuration-drawer-description {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  margin-top: 6px;
}
.configuration-title-add-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.mt-0px {
  margin-top: 0px;
}
.configuration-title-flex-container {
  margin-top: 24px;
  font-family: "Cabin";
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
}
.ticket-drawer-container {
  margin-top: 60px;
}

.field-heading {
  font-size: 1rem;
  line-height: 2rem;
  display: "inline-block";
  font-weight: 600;
}

.help-text {
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin-bottom: 12px;
  color: #a5a5a5;
}
.help-texts {
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin-bottom: 5px;
  color: #a5a5a5;
}

.client-input-group {
  margin-bottom: 10px;
  flex-direction: column;
}

.drag-drop-container {
  border: 2px dotted #666;
  background-color: #ddd;
  border-radius: 0.5em;
  padding: 20px;
  width: 50%;
  font-size: small;
  text-align: center;
  margin-bottom: 20px;
}
