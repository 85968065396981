.sort-component-container {
  border-radius: 4px;
  /* box-shadow: 0 0 5px 1px #00000030; */
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.sort-component {
  border-radius: 4px;
  display: flex;
  background-color: #ffffff;
}
.sort-component-item {
  display: flex;
  padding: 8px;
  padding-right: 24px;
  padding-bottom: 8px;
  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
  min-width: 100px;
}
.sort-component-item-icon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sort-component-item-detail {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.sort-component-item-title {
  font-family: "Cabin";
  font-size: 12px;
  opacity: 0.7;
}
.sort-component-item-value {
  font-family: "Cabin";
  font-weight: bold;
  font-size: 16px;
  margin-top: 4px;
}
.opacity-3 {
  opacity: 0.3;
}
.highlighted {
  border-bottom: 3px solid #db2a5f;
}
.pointer {
  cursor: pointer;
}
.filled-icon {
  color: #db2a5f;
}
.br-sort {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.sort-filter {
  display: flex;
  align-items: center;
}
.mr-8 {
  margin-right: 8px;
}
