.UpdatePasswordContainer {
  min-width: 35%;
  min-height: 50%;

  box-shadow: 1px 2px 2px #073b4c;
  border-radius: 2em;
  border: 1px solid #073b4c;
  background-color: #ffd166;
}

.must-container {
  margin-bottom: 1em;
  background-color: rgba(159, 21, 90, 0.1);
  padding: 15px;
  border-radius: 1.2em;
  margin-top: 16px;
  margin-bottom: 24px;
}

.MustContainItem {
  margin-top: 4px;
  margin-bottom: 4px;
}

.must-item {
  position: relative;
  width: 15em;
  padding: 0.7em 0;
}

.must-text {
  position: absolute;
  z-index: 0;
  top: 0.6em;
  left: 1em;

  width: 100%;

  padding: 0;
  margin: 0;
  line-height: 0;
  font-size: 14px;
  font-family: "Cabin";
}

.must-line {
  position: absolute;
  z-index: 1;
  top: 9px;
  width: 0;
  height: 0.1em;
  background-color: #9f155a;
  transition: 0.7s;
}

.cross-out {
  width: 100%;
}

/* misc styling for form elements */
.MuiFormControlLabel-root label {
  padding-bottom: 1em;
  font-size: 0.8em;
}

input {
  padding: 0.4em 0.5em;
}

h4 {
  padding-top: 1em;
  line-height: 0;
}

.login-page-container {
  height: calc(100vh - 64px);
  width: 100%;
  display: flex;
  background-color: #ffffff;
}

.login-items-container {
  flex: 1;
  height: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: scroll;
}

.login-ads-container {
  flex: 1;
  height: 100%;
  background-image: url("../../../assets/london.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 50px;
}

.login-ad-content {
  padding: 50px;
  line-height: 48px;
  font-size: 32px;
  font-family: "Cabin";
  font-weight: 800;
  color: #a0155a;
}

.login-text {
  font-family: "Cabin";
  font-weight: 800;
  text-align: center;
  font-size: 24px;
}
