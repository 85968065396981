.basic-search-bar {
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100px;
}
.station-text {
  font-size: 30px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Cabin";
}
.description {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.8;
  margin-top: 4px;
  font-family: "Cabin";
}
.station-sub-text {
  font-size: 20px;
  margin-left: 4px;
  font-family: "Cabin";
}
.search-title {
  display: flex;
  font-family: "Cabin";
}
.expand-icon {
  cursor: pointer;
}
.reference-text {
  font-size: 13px;
  font-weight: 600;
  opacity: 0.5;
  cursor: pointer;
  font-family: "Cabin";
}
.pointer {
  cursor: pointer;
}
.uppercase {
  text-transform: uppercase;
}
.error-text {
  margin-top: 8px;
  margin-bottom: 8px;
  color: red;
  font-family: "Cabin";
  font-size: 13px;
}
.destination-icon {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: -16px;
  height: 32px;
  width: 32px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a0155a;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0 0 2px 1px #0000000d;
}
.relative {
  position: relative;
}
.rotate-90 {
  transform: rotate(90deg);
}
.heading-font {
  text-transform: uppercase;
  font-size: 12px !important;
  margin-top: 4px;
  font-weight: normal !important;
  opacity: 1;
}
.no-opacity {
  opacity: 0 !important;
}
.from-to-height-width {
  height: 100%;
  width: 100%;
}
