.number-of-stops-container {
  display: flex;
  flex-direction: column;
  margin-left: 3px;
}
.fare-type-container {
  display: flex;
  flex-direction: column;
  margin-left: 3px;
}
.mt-12 {
  margin-top: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.stops-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.stop-item-price {
  font-family: "Cabin";
  font-size: 13px;
  opacity: 0.7;
  font-weight: bold;
  text-align: right;
}
.checkbox {
  height: 14px;
  width: 14px;
}
.prices-filter-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prices-filter-value {
  margin-top: 16px;
}
.prices-filter-start-value {
  font-family: "Cabin";
  font-size: 13px;
  opacity: 0.7;
  font-weight: bold;
}
.prices-filter-end-value {
  font-family: "Cabin";
  font-size: 13px;
  opacity: 0.7;
  font-weight: bold;
}
.depart-arrival-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.depart-arrival-item {
  margin-left: 3px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex-direction: column;
  cursor: pointer;
  flex-grow: 1;
  background-color: #ffffff;
}
.depart-arrival-text {
  text-align: center;
  font-family: "Cabin";
  font-size: 9px;
  font-weight: bold;
  margin-top: 2px;
  margin-bottom: 4px;
}
.depart-arrival-icon {
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
}
.depart-arrival-item-checked {
  background-color: #9f155a;
  color: #ffffff !important;
}
