.modify-search-bar-container {
  width: 100%;
  z-index: 15;
}

.modify-search-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modify-search-block {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  letter-spacing: 1px;
  color: #ffffff;
  font-family: "Cabin";
  text-transform: uppercase;
  font-weight: bold;
  z-index: 15;
  background-image: linear-gradient(96deg, #db2a5f, #a0155a);
}

.search-info-container {
  height: 100%;
  border-radius: 4px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.height-100 {
  height: 100%;
}

.search-info-title {
  font-family: "Cabin";
  font-size: 12px;
  color: #db2a5f;
  font-weight: bold;
  margin: 6px;
  margin-left: 8px;
  margin-top: 8px;
}

.search-info-description {
  font-size: 15px;
  font-family: "Cabin";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  margin-left: 8px;
}

.pointer {
  cursor: pointer;
}

.opacity-5 {
  opacity: 0.5;
}