.hold-close-button-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: right;
}

.hold-close-button {
    text-transform: none !important;
    color: #dc3545 !important;
    border: 1px solid #dc3545 !important;
    /* float:right !important; */
}

.hold-close-button:hover {
    color: #fff !important;
    background-color: #dc3545 !important;
    /* border:1px solid #dc3545 !important; */
    text-transform: none !important;
}

.hold-submit-button {
    text-transform: none;
    color: white;
    background-color: #9f155a;
    float: right;
    margin-bottom: 10px !important;
}

.holdAmountRow {
    display: flex;
    align-items: baseline;
    align-content: center;
    flex-wrap: wrap;
    border: 1px dotted #000;
    margin-bottom: 10px ;
}
.holdAmountRow p {
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
}
.holdAmountRow span {
    font-size: 20px;
    font-weight: bolder;
}
.refundable{
    /* background-color: green;
    color: white; */
    color: green;
    padding: 2px;
    margin-right: 5px;
}
.nonRefundable{
    /* background-color: red; */
    color: red;
    /* color: white; */
    padding: 2px;
    margin-right: 5px;
}
.hold-rules-modal p {
font-size: 14px !important;
}