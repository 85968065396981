.configuration-view-edit-drawer-container {
  padding: 24px;
  /* margin-top: 32px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.configuration-view-edit-drawer-if-container {
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 24px;
  margin-bottom: 24px;
}
.configuration-view-edit-drawer-if-line {
  height: 1px;
  border-bottom: 1px solid #000000;
  opacity: 0.1;
  width: 50%;
  margin-left: 16px;
  margin-right: 16px;
}
.configuration-view-edit-drawer-input-container {
  display: flex;
  width: 100%;
  margin-top: 24px;
}
.margin-right-16 {
  margin-right: 16px;
}
.config-input-flex-1 {
  flex: 1;
}
.config-margin-top-32 {
  margin-top: 40px;
}
.add-configuration-button-container-update-new {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #a0155a;
  color: #a0155a;
  border-radius: 4px;
  margin-top: 24px;
  margin-right: 24px;
  margin-left: 24px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-configuration-button-container-update-new-button {
  cursor: pointer;
  text-align: center;
}
.edit-view-drawer-heading {
  font-family: "Cabin";
  font-weight: 600;
  font-size: 20px;
  margin-top: 24px;
  margin-left: 24px;
}
.view-configuration-drawer {
  display: flex;
  flex-direction: column;
}
.view-configuration-drawer-title {
  font-family: "Cabin";
  font-size: 12px;
  opacity: 0.6;
}
.view-configuration-drawer-description {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 14px;
  margin-top: 6px;
}
.configuration-title-add-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.mt-0px {
  margin-top: 0px;
}
.configuration-title-flex-container {
  margin-top: 24px;
  font-family: "Cabin";
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
}

.configuration-title-helptext {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.7;
  font-size: 15px;
}

.add-client-role-drawer {
  height: 100vh;
  padding-top: 8vh;
  border: 7px solid lightgrey;
}

.close-btn-add-crole {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
  width: 100% !important;
  border-top: 7px solid lightgrey;
}
