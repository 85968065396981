.add-search-card-container {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 24px;
  padding-top: 24px;
  padding-bottom: 4px;
  box-shadow: 0px 0px 5px 3px #0000000a;
}
.add-search-card-title {
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 16px;
}
.create-importsearch-contents-container {
  margin-top: 24px;
  margin-bottom: 24px;
  /* display: flex; */
}
.create-search-name-container {
  /* width: 50%; */
  margin-bottom: 16px;
}
.add-search-if-container {
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 14px;
}
.create-search-contents {
  flex: 0.7;
}
.create-search-image {
  flex: 0.4;
  max-height: 240px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add-search-if-items-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.flex-1-input {
  flex: 1;
}
.margin-right-8 {
  margin-right: 8px;
}
.margin-right-16 {
  margin-right: 16px;
}
.fshSearch {
  margin-bottom: 5vh;
  background: #fff;
  padding: 2vh 0;
}
