.manage-profile-container {
  margin-top: 25px;
}

.manage-profile-content {
  padding: 100px;
}

.profile-header-user-details-container {
  display: flex;
  margin-top: 40px;
  margin-bottom: -8px;
  margin-left: 24px;
  margin-right: 24px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 14px;
  padding-top: 32px;
  padding-bottom: 32px;
  box-shadow: 0px 0px 5px 3px #0000000a;
}

.profile-header-user-details-avatar-container {
  padding-left: 8px;
  height: 64px;
  width: 64px;
}

.profile-header-user-details-detailed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
  margin-left: 24px;
}

.profile-header-user-details-username {
  font-family: "Cabin";
  font-size: 24px;
}

.profile-header-user-details-user-role {
  font-family: "Cabin";
  font-size: 16px;
  margin-top: 3px;
  opacity: 0.5;
}

.avatar-component {
  height: 64px !important;
  width: 64px !important;
}

.profile-item {
  margin-top: 48px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 24px;
  margin-right: 24px;
}

.profile-item-text {
  font-family: "Cabin";
  font-weight: 16px;
}

.profile-item-user-details-container {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 14px;
  box-shadow: 0px 0px 5px 3px #0000000a;
}

.profile-items-flex {
  display: flex;
}

.profile-item-user-details {
  display: flex;
  margin-bottom: 16px;
}

.profile-item-user-details-text {
  flex: 1;
  font-family: "Cabin";
  font-weight: 16px;
  font-weight: 600;
}

.profile-item-user-details-subtext {
  flex: 1;
  font-family: "Cabin";
  font-weight: 16px;
}

.profile-item-title {
  font-family: "Cabin";
  font-weight: 600;
  margin-top: 8px;
}

.table-column-group {
  display: table-column-group !important;
}

.custom-role-chip {
  margin: 6px;
}

.profile-item .markup-btn {
  margin-top: 15px;
  border: 1px solid rgba(120, 116, 116, 0.12);
  width: 300px;
  padding: 10px;
  color: black;
  cursor: pointer;
  background-color: rgb(243, 243, 241);
  box-shadow: -3px 1px;
}

.languageSelect .MuiInputBase-input {
  padding-left: 0 !important;
}