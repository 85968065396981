

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}




* {
  box-sizing: border-box;
}
.iconDisplay{
  position: absolute;
    bottom: 0;
    margin: 0;
}
.countDisplay{
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  background: #d63384;
  color: #fff;
  padding: 5px;
  border-radius: 50%; 
  font-size: 10px;
  width: 20px; 
  height: 20px;
  display: flex;
  justify-content: center; 
  align-items: center; 
}
.ScheduleItienaryText p {
    font-size: 0.8em;
}

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}



.TravelSchedule {
  display: block;
  position: relative;
  width: 100%;
  /* background: var(--neutral-color); */
  /* border: 1px solid var(--border-color); */
  padding: 0 1vw;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.TravelSchedule .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.TravelSchedule .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.TravelSchedule .header .icon:hover {
  transform: scale(1.75);
  transition: .25s ease-out;
  color: var(--main-color);
}

.TravelSchedule .header .icon:first-of-type {
  margin-left: 1em;
}

.TravelSchedule .header .icon:last-of-type {
  margin-right: 1em;
}

.TravelSchedule .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.TravelSchedule .body .cell {
  position: relative;
  height: 5em;
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TravelSchedule .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}
/* Right Stripe */
/* .TravelSchedule .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #d63384 0%, #dd4892 40%);
  border-image-slice: 1;
} */


/* Triangles */
.TravelSchedule .body .selected {
  position: relative; 
}

.TravelSchedule .body .selected::before {
  content: ""; 
  position: absolute; 
  top: 0; 
  left: -10px; 
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px; 
  border-right-color: transparent;
  border-bottom-color: #d63384; 
  border-left-color: transparent;
}

/* Glow Effect */
/* .TravelSchedule .body .selected {
  box-shadow: 0 0 10px 5px rgba(214, 51, 132, 0.7); 
} */

/* Background Overlay */
/* .TravelSchedule .body .selected {
  position: relative;
}

.TravelSchedule .body .selected::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(214, 51, 132, 0.3);
} */

/* Border Animation */
/* @keyframes borderAnimation {
  0% { border-color: #d63384; }
  50% { border-color: #dd4892; }
  100% { border-color: #d63384; }
}

.TravelSchedule .body .selected {
  animation: borderAnimation 1s infinite; 
} */

/* Scale Effect: */
/* .TravelSchedule .body .selected {
  transform: scale(1.1); 
} */

/* Opacity Transition */
/* .TravelSchedule .body .selected {
  transition: opacity 0.3s ease; 
}

.TravelSchedule .body .selected:hover {
  opacity: 0.8; 
} */


.TravelSchedule .body .row {
  border-bottom: 1px solid var(--border-color);
}

.TravelSchedule .body .row:last-child {
  border-bottom: none;
}

.TravelSchedule .body .cell:last-child {
  border-right: none;
}

.TravelSchedule .body .cell .number {
  font-size: 82.5%;
  line-height: 1;
  font-weight: 700;
}

.TravelSchedule .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.TravelSchedule .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.TravelSchedule .body .cell:hover .bg, .TravelSchedule .body .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.TravelSchedule .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
  border: 1px solid #e9e6e6;
}