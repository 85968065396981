/* ===================== Container =================== */
.passthroughConfiguration-container {
  width: 100vw;
  height: 100%;
  padding: 1% 0.5%;
}

.passthrough-row {
  /* padding: 0.5% 3%; */
  margin: 0.5% auto;
}

/* ===================== Header =================== */
.passthrough-header {
  display: flex;
  justify-content: space-between;
}

.passthrough-header-text {
  font-family: "Cabin";
  font-weight: 300;
  font-size: 21px;
  opacity: 0.7;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #340f34;
}

.add-rules-config {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #a0155a !important;
  color: #a0155a !important;
  opacity: 1;
  border-radius: 5px;
}

.add-rules-config:hover {
  opacity: 0.8;
}

.add-rules-config:focus,
.modal_close:focus {
  box-shadow: none;
}

/* ===================== Table =================== */
.ag-root-wrapper {
  border-radius: 5px;
}

.ticket-config-table-header,
.ticket-config-table-header:hover {
  background-color: #340f34 !important;
  color: #ffff !important;
}

.ag-icon,
.ag-icon:hover,
.ag-icon:active {
  color: #340f34 !important;
}

.ag-icon-asc,
.ag-icon-asc:hover,
.ag-icon-desc,
.ag-icon-desc:hover,
.ag-icon-menu,
.ag-icon-menu:hover {
  color: #ffff !important;
}

.ticket-config-cell-renderer {
  line-height: 1.5;
}

.ticket-config-cell-renderer .modified-title {
  font-family: "Cabin" !important;
  opacity: 0.7 !important;
  color: #000000 !important;
}

.ticket-config-cell-renderer .modified-description {
  font-family: "Cabin" !important;
  opacity: 1 !important;
  font-size: 12px;
  color: #898383 !important;
}

/* ===================== Modal =================== */
.ticket-config-modal {
  z-index: 9999;
}

.ticket-config-dialog {
  z-index: 99999 !important;
}

.modal-title {
  color: #9f155a;
}

.modal_close {
  margin-right: 1% !important;

  transition: transform 0.4s;
  -webkit-transition: transform 0.4s;
  -moz-transition: transform 0.4s;
  -ms-transition: transform 0.4s;
}

.modal_close:hover {
  color: #9f155a;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.modal_submit_btn {
  background-color: #9f155a !important;
  color: #ffff !important;
  border: 1px solid #eee !important;
}

.modal_submit_btn:hover {
  background-color: #ffff !important;
  color: #340f34 !important;
  border: 1px solid #340f34 !important;
}

.ticket-config-inputs label.MuiFormLabel-colorSecondary.Mui-focused {
  color: #340f34;
}

.ticket-config-inputs
  .MuiFilledInput-colorSecondary.MuiFilledInput-underline::after {
  border-bottom-color: #340f34;
}

.padd-1pt {
  padding: 0.5%;
}

.cardModal-padd-1pt {
  padding: 0.5%;
  text-align: center;
}

.cardModal-padd-1pt input {
  padding: 0.4em 0.5em;
  width: 100% !important;
  max-width: 100% !important;
}

.creditCard .rccs {
  margin: 0 auto 10px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  width: 290px;
}

.cardModal-padd-1pt p {
  padding: 0px;
}

.MuiAutocomplete-popper {
  z-index: 99999 !important;
}

.action-text {
  text-transform: capitalize;
}

.ticket-config-action-btn {
  margin: 3%;
  color: #000000;
}

.ticket-config-action-btn:hover {
  background-color: #340f34 !important;
  color: #ffff !important;
}

.removeCardButton {
  color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
  margin-top: 10px !important;
  text-transform: capitalize !important;
  float: left !important;
}

/* .removeCardButton:hover{
  color: #fff !important;
  background-color:  #dc3545 !important;
  border: 1px solid #dc3545 !important;
  transition: 0.7s;
}
.removeCardButton:focus{
  color: #fff !important;
  background-color:  #dc3545 !important;
  border: 1px solid #dc3545 !important;
  transition: 0.7s;
} */
.removeCardButton:active {
  color: #fff !important;
  background-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
  transition: 0.7s;
}

.creditcardsList {
  padding: 10px !important;
}

.selectCardButton {
  color: #198754 !important;
  border: 1px solid #198754 !important;
  float: right !important;
  margin-top: 10px !important;
  text-transform: capitalize !important;
}

/* .selectCardButton:hover {
  color: #fff !important;
  background-color: #198754 !important;
  border: 1px solid #198754 !important;
  transition: 0.7s;
}
.selectCardButton:focus {
  color: #fff !important;
  background-color: #198754 !important;
  border: 1px solid #198754 !important;
  transition: 0.7s;
} */
.selectCardButton:active {
  color: #fff !important;
  background-color: #198754 !important;
  border: 1px solid #198754 !important;
  transition: 0.7s;
}

.cardActionButtonContainer {
  margin: 0 auto;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  width: 290px;
}
