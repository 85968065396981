.passenger-profile-main-div {
  background-color: white;
  margin: 10px;
}

.div-bottom-border {
  border-bottom: 1px solid rgb(61 60 60 / 12%) !important;
}

.profile-div-bottom-border {
  border-bottom: 4px solid rgb(61 60 60 / 12%) !important;
  padding-bottom: 1rem;
}

.srn-btn {
  text-transform: capitalize !important;
  background-image: linear-gradient(96deg, #db2a5f, #a0155a) !important;
  color: #fff !important;
  border-color: #9f155a !important;
  min-width: 100px !important;
  font-weight: 600 !important;
}

.passenger-drawer-form {
  padding: 20px;
}

.passenger-font-size-500 {
  font-weight: 500;
}
