.table-cell-title {
  /* font-weight: 500; */
  font-family: "Cabin" !important;
  opacity: 0.7 !important;
  color: #000000 !important;
}

.table-cell-description {
  font-family: "Cabin" !important;
  opacity: 0.4 !important;
  font-size: 12px;
  color: #000000 !important;
  margin-top: 2px;
}

.table-background {
  background-color: #ffffff !important;
}

.table-paper-styles {
  border-radius: 14px !important;
  background: #ffffff;
  box-shadow: 0px 0px 5px 3px #0000000a !important;
}

.pointer {
  cursor: pointer;
}

.table-loading {
  height: 64px;
  display: flex;
  align-items: center;
  margin: 16px;
}

.vendor-listing .MuiTableCell-head {
  font-weight: bold !important;
  font-size: 13px !important;
  opacity: 1;
}

.vendor-listing .MuiOutlinedInput-root {
  height: 30px;
  width: 100%;
  margin-top: 6px;
}

.ag-theme-material .ag-cell-value,
.ag-group-value {
  overflow: hidden !important;
  text-overflow: clip !important;
}

.vendor-listing .ag-theme-material .ag-cell,
.ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  border: 1px solid transparent;
  line-height: min(var(--ag-line-height, 46px), 46px);
  padding-left: 5px !important;
  padding-right: 5px !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

.vendor-listing .ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ticket-config-cell-renderer .MuiChip-root {
  margin: 20% 0% !important;
}
