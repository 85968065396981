.po-header {
  background-color: #dfd0d04a;
  font-weight: 500;
}

.pricing-options-container {
  padding: 20px;
  margin: 0 auto;
  width: 45rem;
}
