.facetbox {

}

.box {
    height: 100%;
}

.listitem {
    padding-left: 0px !important;
}

.filterlist {
    list-style: none;
    padding: 0px;
}

.chip {
    margin: 0.25em;
}