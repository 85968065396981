.add-supplier-card-container {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: 0px 0px 5px 3px #0000000a;
}
.add-supplier-card-title {
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 16px;
}
.create-supplier-contents-container {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
}
.create-supplier-name-container {
  /* width: 50%; */
  margin-bottom: 16px;
}
.add-supplier-if-container {
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 14px;
}
.create-supplier-contents {
  flex: 0.7;
}
.create-supplier-image {
  flex: 0.4;
  max-height: 240px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add-supplier-if-items-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.add-supplier-if-item {
  /* flex: 1; */
  /* margin-right: 8px; */
}

.flex-1-input {
  flex: 1;
}
.margin-right-8 {
  margin-right: 8px;
}
.margin-right-16 {
  margin-right: 16px;
}

.add-supplier-button {
  margin-top: -3px !important;
}

.scb-custom-dropdown-height .MuiAutocomplete-inputRoot {
  height: 40px !important;
}

.scb-custom-dropdown-height .MuiOutlinedInput-root {
  height: 100% !important;
  padding: 0 !important;
}

.scb-custom-dropdown-height .MuiAutocomplete-input {
  padding: 11px !important;
}
.scb {
  padding-top: 4px !important;
}
