.login-page-container-signin {
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.login-items-container-signin {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #fafafa2b; */
  background-image: url("../../assets/bg-v2-3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.signin-container {
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px #00000021;
  width: 24vw;
  /* height: 500px; */
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: -24%;
  border-radius: 4px;
  right: 4rem;
}

.signin-info-container {
  padding: 64px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px #00000012;
  width: 40vh;
  height: 500px;
  display: flex;
  flex-direction: column;
  line-height: 48px;
  font-size: 32px;
  font-family: "Cabin";
  font-weight: 800;
  color: #a0155a;
  justify-content: center;
}

.forgot-password-container {
  color: #a0155a;
  font-size: 14px;
  margin-top: 12px;
  cursor: pointer;
}

.newsletter-container {
  height: 70vh;
  background-image: url("../../assets/gyb.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5rem;
}

.newsletter-text {
  color: #ffffff;
  font-size: 48px;
  font-family: "Cabin";
  font-weight: bold;
}

.newsletter-sub-text {
  color: #ffffff;
  margin-top: 8px;
  font-family: "Cabin";
  font-size: 18px;
}

.newsletter-input-container {
  display: flex;
  margin-top: 32px;
}

.newsletter-submit-button {
  height: 55px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #a0155a;
  box-shadow: 0px 0px 6px 0px #00000040;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}

.about-info-container {
  /* height: 70vh; */
  padding-left: 10px;
}

.signin-text-container {
  font-size: 17px;
  font-family: "Cabin";
  font-weight: bold;
  margin-bottom: 24px;
}

.about-info-heading {
  font-size: 25px;
  font-family: "Cabin";
  font-weight: bold;
  margin-top: 24px;
}

.about-info-content {
  margin-top: 24px;
  width: 50%;
  line-height: 32px;
  font-size: 16px;
  padding-bottom: 40px;
  opacity: 0.7;
}

.forgot-main-heading {
  font-size: 15px;
  color: #ab155a;
  font-weight: 600;
}

.forgot-sub-heading {
  font-size: 11px;
  font-weight: 500;
  color: #000000;
}

.login-hyperlink {
  color: #A51A5B;
  cursor: pointer;
  float: right;
  left: 10%;
  position: absolute;
  /* bottom: -14px; */
}

.forgot-password-section .MuiInputBase-formControl,
.sign-in-section .MuiInputBase-formControl {
  border-bottom: unset !important;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  /* background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important; */
  color: fieldtext !important;
  background-color: transparent !important;
  background: none !important;
}


.signInInput {
  min-height: 3rem !important;
  margin: 10px !important;
  min-width: 35% !important;
  border: 0.5px solid rgba(165, 26, 91, 0.30) !important;
  border-radius: 50px !important;
  --Input-focusedHighlight: rgba(165, 26, 91, 0.52) !important;
  box-shadow: none !important;
  width: 278px;
  padding: 0px 25px !important;
}

.signInInput::before {
  box-shadow: none !important;
}



.MuiInput-input:-webkit-autofill {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  --Input-focusedHighlight: rgba(165, 26, 91, 0.52) !important;
}

.sign-in-section .MuiInputBase-root {
  padding: 0.2em;
}

.sign-in-section .MuiOutlinedInput-input {
  padding: 0px;
}

.headerContainer {
  min-width: 100% !important;
}



.headerContainer.MuiPopover-paper {
  opacity: 1;
  transform: none;
  transition: opacity 291ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 194ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 15% !important;
  left: 1120px;
  transform-origin: 416px -52px;
  min-height: 250px !important;
}

.forgotPassword {
  opacity: 1;
  transform: none;
  transition: opacity 291ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 194ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 10% !important;
  left: 1120px;
  transform-origin: 416px -52px;
  min-height: 250px !important;
}




.products .products-item span {
  padding-left: 0.5em;
  white-space: nowrap;
}

.products .products-item {
  padding: 0 1rem;
}

.products .products-item:nth-child(odd) {
  padding-left: 0;
}

.products .products-item:last-child {
  padding-right: 0;
}

.banner-carousel,
.banner-carousel .carousel-inner,
.banner-carousel .carousel-item {
  height: 100%;
}

.icon-bk {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

@media (max-width: 1300px) {
  .signInInput{
    width: 350px;
  }
}

@media (max-width: 600px) {
  .signInInput{
    width: 100%;
  }
}