.curr-bal-tu {
  background-color: white;
  border: 1px solid #9f155a;
  border-radius: 5px;
}

.topupContainer .tab-select {
  background-color: #9f155a !important;
  color: #fff !important;
  margin-bottom: 7px;
}
.topupContainer .tab-unselect {
  background-color: white !important;
  color: #9f155a !important;
  margin-bottom: 7px;
}

.topupContainer .css-10d9dml-MuiTabs-indicator {
  background-color: #9f155a;
}

.topupContainer .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #9f155a !important;
}

.paymentInput {
  max-width: 15vw !important;
  float: right !important;
  min-width: 15vw !important;
}

.topupContainer .css-j204z7-MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.topupContainer .tokencb-txt {
  font-size: 14px;
}
