.ticket-drawer-container {
  height: 100vh;
  position: relative;
}
.search-result-drawer-container {
  width: 37vw !important;
}
.close-button-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.icon-fill {
  /* fill: #db2a5f !important; */
  fill: #a0155a !important;
} 
.drawer-ticket-details-container {
  padding-top: 16px;
  padding-bottom: 140px;
}
.drawer-from-to-header {
  padding-left: 24px;
  padding-right: 24px;
  font-size: 24px;
  font-family: "Cabin";
  /* font-weight: bold; */
}
.pointer {
  cursor: pointer;
}
.drawer-via-header {
  font-size: 14px;
  font-family: "Cabin";
  margin-top: 4px;
  opacity: 0.5;
}
.drawer-search-date {
  font-size: 14px;
  font-family: "Cabin";
  margin-top: 4px;
  opacity: 0.7;
  margin-left: 12px;
}
.drawer-search-flight {
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.br-result-item {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.pr-8 {
  padding-right: 8px;
}
.show-flight-details {
  font-size: 14px;
  font-family: "Cabin";
  margin-top: 24px;
  margin-bottom: 24px;
  color: #db2a5f;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 16px;
}
.show-flight-details-text {
  margin-left: 0px !important;
}
.h1 {
  height: 1px;
}
.bb-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.mt-24 {
  margin-top: 24px;
}
.drawer-fare-header {
  padding-left: 24px;
  padding-right: 24px;
  font-size: 20px;
  font-family: "Cabin";
}
.drawer-fare-container {
  border: 2px solid #000000;
  border-radius: 8px;
  margin-left: 26px;
  margin-right: 26px;
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 24px;
}
.mt-16 {
  margin-top: 16px;
}
.drawer-base-fare {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drawer-tax-fare {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drawer-fare-total {
  font-size: 24px;
  font-family: "Cabin";
}
.drawer-fare-description {
  font-size: 14px;
  font-family: "Cabin";
  opacity: 0.7;
  font-weight: 300;
}

.drawer-fare-description th.left {
  text-align: left;
}

.drawer-fare-description th.right {
  text-align: right;
}

.mini-fares-rules-table {
  width: 100%;
  padding: 0.5% 2%;
}

.mini-fares-rules-table th {
  padding : 0.5%;
}

.refundable-ticket {
  font-size: 14px;
  font-family: "Cabin";
  opacity: 0.7;
  font-weight: 300;
  margin-top: 6px;
}
.mb-8 {
  margin-bottom: 8px;
}
.drawer-total-container {
  z-index: 1;
  position: fixed;
  height: 70px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px #00000036 !important;
  bottom: 0;
  right: 0;
  left: 63vw;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}
.text-bold {
  font-weight: bold;
}
.drawer-total-container-price {
  display: flex;
  flex-direction: column;
}
.drawer-total-price {
  font-size: 20px;
  font-family: "Cabin";
  font-weight: bold;
}
.drawer-total-price-per-description {
  font-size: 12px;
  font-family: "Cabin";
  opacity: 0.7;
}
.drawer-total-price-all-description {
  font-size: 12px;
  font-family: "Cabin";
  font-weight: bold;
  margin-top: 4px;
}
.drawer-modify-search-block {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  height: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  letter-spacing: 1px;
  color: #ffffff;
  font-family: "Cabin";
  text-transform: uppercase;
  font-weight: bold;
  background-image: linear-gradient(96deg, #db2a5f, #a0155a);
}
.drawer-cancellation {
  margin-bottom: 24px;
}
.drawer-date-change {
  margin-bottom: 24px;
}
.mt-8 {
  margin-top: 8px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.opacity-9 {
  opacity: 0.9 !important;
}
.ml-12 {
  margin-left: 12px;
}
.primary-fill {
  fill: #9f155a !important;
}
