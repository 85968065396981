.add-configuration-card-container {
    background-color: #ffffff;
    border-radius: 14px;
    padding: 24px;
    padding-top: 24px;
    padding-bottom: 4px;
    box-shadow: 0px 0px 5px 3px #0000000a;
  }
  .add-configuration-card-title {
    font-family: "Urbanist";
    font-weight: 600;
    font-size: 16px;
  }
  .create-configuration-contents-container {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
  }
  .create-configuration-name-container {
    /* width: 50%; */
    margin-bottom: 16px;
  }
  .add-configuration-if-container {
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: "Urbanist";
    font-weight: 600;
    font-size: 14px;
  }
  .create-configuration-contents {
    flex: 0.7;
  }
  .create-configuration-image {
    flex: 0.4;
    max-height: 240px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .add-configuration-if-items-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  /* .add-configuration-if-item { */
    /* flex: 1; */
    /* margin-right: 8px; */
  /* } */
  .flex-1-input {
    flex: 1;
  }
  .margin-right-8 {
    margin-right: 8px;
  }
  .margin-right-16 {
    margin-right: 16px;
  }
 .add-supplier-if-items-container .MuiInputLabel-root {
    font-size: 14px !important;
    font-family: "Cabin" !important;
    background: none !important;
    top: -4px !important;
    padding: 0 8px!important;
}
.add-supplier-if-items-container  .MuiOutlinedInput-input {
  padding: 18.5px 14px !important;
}
.add-supplier-if-items-container .MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(0, 24px) scale(1);
}
.create-supplier-contents-container {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
}
.add-supplier-card-container .create-supplier-contents-container{
  margin-top: 24px;
  margin-bottom: 24px;
  display: block !important;
}
.add-supplier-button-container {
  display: inline-block;
  cursor: pointer;
}
.add-supplier-card-container .add-supplier-button{
  max-width: 100px;
  padding: 5px;
  cursor: pointer;
}
.minWidth20{
  min-width: 15%!important;
  max-width: 20%!important;
}
.minMax10{
  min-width: 10%!important;
    max-width: 10%  !important;
}