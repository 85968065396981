.tooltip-trigger .tooltip {
    background-color: rgb(83, 80, 80);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    opacity: 0;
    transition: opacity 1s;
    position: absolute;
    z-index: 1;
}

.tooltip-trigger {
    position: relative;
    display: inline;
}

.tooltip-trigger:hover .tooltip {
    opacity: 1;
}

.tooltip-trigger .tooltip {
    opacity: 0;
}