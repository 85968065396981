.basic-search-bar {
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100px;
}
.station-text {
  font-size: 30px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Cabin";
}
.description {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.8;
  margin-top: 4px;
  font-family: "Cabin";
}
.station-sub-text {
  font-size: 20px;
  margin-left: 4px;
  font-family: "Cabin";
}
.search-title {
  display: flex;
}
.expand-icon {
  cursor: pointer;
}
.reference-text {
  font-size: 13px;
  font-weight: 600;
  opacity: 0.5;
  cursor: pointer;
}
.add-city-button-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.remove-icon {
  opacity: 0.4;
}
.pointer {
  cursor: pointer;
}
.heading-font {
  text-transform: uppercase;
  font-size: 12px !important;
  margin-top: 4px;
  font-weight: normal !important;
  opacity: 1;
}

.mb-3p {
  margin-bottom: 3% !important;
}
