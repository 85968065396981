.custom-font {
  font-family: "Cabin";
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  padding-left: 3px;
  padding-right: 3px;
}
.trip-type-radio-buttons {
  display: flex;
}
.trip-type-radio-button {
  margin-right: 12px;
}

.custom-font {
  font-family: "Cabin";
}
.trip-type-select-width {
  max-width: 100px;
}
.preference-type-select-width {
  max-width: 200px;
}
