.view-booking-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.itenary-container-header {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 25px !important;
  color: #340f2f;
  padding-bottom: 1%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}
.double-btn {
  display: flex;
  justify-content: center;
}
.parent-flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
}
.current-div {
  margin-top: 20px;
}
.currentstatus-btn {
  background: #006644 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 4px !important;
}
.cancelref-btn {
  background: #a0155a !important;
  color: #ffffff !important;
  font-size: 14px !important;
  margin-right: 20px !important;
}
.print-btn {
  background: #a0155a !important;
  color: #ffffff !important;
  margin-left: 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 3px !important;
  text-transform: capitalize !important;
}
.refund-btn {
  background: #340f2f !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
.master-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 750px;
}
.refsubmit-btn {
  background: #340f2f !important;
  color: #ffffff !important;
  font-size: 14px !important;
  /* margin-left: 900px !important; */
}
.disabled-btn {
  opacity: 0.1;
  cursor: not-allowed;
  background-color: #dcdcdc;
  color: #888888;
  border: 1px solid #cccccc;
}
.remark-container {
  border: "0.5px solid grey";
  padding: 25px;
  border-radius: 5px;
  margin: 20px 0;
  box-sizing: border-box;
  width: 1000px;
}

.pt-2p {
  padding-top: 2%;
}

.tkt-btn {
  /* background: #340f2f !important; */
  color: #ffffff !important;
}

.tkt-btn:hover {
  background: #340f2f !important;
}

.srn-btn {
  text-transform: capitalize !important;
  background-image: linear-gradient(96deg, #db2a5f, #a0155a) !important;
  /* background: #9f155a !important; */
  color: #fff !important;
  border-color: #9f155a !important;
  min-width: 100px !important;
  font-weight: 600 !important;
}
.srn-btn:disabled {
  text-transform: capitalize !important;
  background-image: linear-gradient(96deg, #0000005e, #00000042) !important;
  /* background: #9f155a !important; */
  color: #fff !important;
  border-color: #9f155a !important;
  min-width: 100px !important;
  font-weight: 600 !important;
}

.booking_status {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.cancel-btn {
  background: #d31204 !important;
  color: #ffffff !important;
  margin-left: 6px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 3px !important;
  text-transform: capitalize !important;
}

.cancel-btn:hover {
  background: #d31204 !important;
}

.itenary-container {
  font-family: "Cabin";
  font-weight: 700;
  font-size: 25px !important;
  color: #340f2f;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itenary-container-staff {
  display: contents;
  font-family: "Cabin";
  font-weight: 700;
  font-size: 32px;
  color: #340f2f;
  align-items: center;
  justify-content: space-between;
}

.itenary-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itenary-status-font {
  font-size: 14px;
  font-weight: 300;
}

.itenary-confirmtaion-tag {
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  margin: 1rem 0;
}

.booking-padding-top-40 {
  padding-top: 40px;
}

.viewBooking .flight-itenary {
  background-color: #ffffff;
  border-radius: 14px;
  margin-top: 24px;
}

.viewBooking .viewBooking .flight-itenary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #340f34;
  color: #ffffff;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}

.viewBooking .flight-itenary-title-container {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  font-family: "Cabin";
}

.viewBooking .flight-itenary-date {
  font-size: 16px;
  font-weight: 500;
}

.viewBooking .flight-itenary-icon {
  opacity: 0.4;
  margin-left: 6px;
  margin-right: 6px;
  height: 24px;
  width: 24px;
}

.viewBooking .flight-itenary-details {
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.viewBooking .flight-itenary-details-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 100px;
}

.viewBooking .flight-itenary-details-item-text {
  font-size: 18px;
  font-weight: 500;
}

.viewBooking .flight-itenary-details-item-subtext {
  font-size: 12px;
  font-weight: 300;
  /* opacity: 0.4; */
  margin-top: 4px;
}

.viewBooking .flight-confirmation-details {
  margin: 16px 24px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.viewBooking .flight-confirmation-details-item-text {
  font-size: 13px;
  font-weight: 500;
}

.viewBooking .flight-confirmation-details-item-subtext {
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  opacity: 0.6;
}

.viewBooking .flight-confirmation-details-item {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.full-opacity {
  opacity: 1 !important;
}

.viewBooking .itenary-trip-details {
  flex-direction: column;
  margin: 16px 24px;
  padding-bottom: 8px;
  display: flex;
}

.viewBooking .itenary-flight-details {
  flex: 1;
}

.itenary-trip-from-to-container {
  flex: 1;
  display: flex;
}

.font-size-14 {
  font-size: 14px !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.viewBooking .flight-itenary-to-container {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.5;
}

.itenary-to-line {
  flex: 1;
  height: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.itenary-to-text {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 12px !important;
  opacity: 0.6;
  font-weight: 500;
}

.itenary-passenger-details {
  width: 100%;
  margin-top: 24px;
}

.viewBooking .itenary-passenger-table {
  width: 100%;
  border-spacing: 1px;
  text-align: left !important;
}

.viewBooking .itenary-passenger-table-header {
  height: 48px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #340f34;
  color: #ffffff;
}

.header-item-font-weight {
  font-weight: 400;
}

.viewBooking .itenary-passenger-table-header-pl-12 {
  padding-left: 12px;
}

.viewBooking .itenary-passenger-table-header-pl-30 {
  padding-left: 30px;
}

.viewBooking .itenary-passenger-table-header-item-initial {
  border-top-left-radius: 8px;
  /* border-bottom-left-radius: 8px; */
}

.viewBooking .itenary-passenger-table-header-item-final {
  border-top-right-radius: 8px;
  /* border-bottom-right-radius: 8px; */
}

.viewBooking .itenary-passenger-table-item-initial {
  /* border-top-left-radius: 8px; */
  border-bottom-left-radius: 8px;
}

.viewBooking .itenary-passenger-table-item-final {
  /* border-top-right-radius: 8px; */
  border-bottom-right-radius: 8px;
}

.viewBooking .itenary-passenger-table-item {
  background-color: #ffff;
  color: #000000;
  /* border-radius: 8px; */
}

.viewBooking .itenary-passenger-table-h-64 {
  height: 48px;
  line-height: 4.7vh;
  color: #2c2c2ce8;
  padding: 10px;
}

.width25 {
  width: 25% !important;
}

.width20 {
  width: 20% !important;
}

.width15 {
  width: 15% !important;
}

.width10 {
  width: 10% !important;
}

.textCenter {
  text-align: center !important;
}

.viewBooking .itenary-passenger-table-h-64 span {
  opacity: 1;
}

.s-no {
  max-width: 16px;
}

.fare-details {
  width: 100%;
  margin-top: 24px;
}

.fare-detail-container {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 24px;
}

.fare-item-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}

.fare-item-less-opacity {
  opacity: 0.7;
}

.fare-item-container-padding {
  padding-top: 8px;
}

.fare-item-total-padding {
  padding-top: 16px;
  padding-right: 45px;
}

.price-row {
  border-bottom: 0.3px solid #cccccc;
  border-top: 0.3px solid #cccccc;
  padding-bottom: 15px;
}

.baggage-price-label {
  font-size: 14px !important;
  font-weight: 600;
  padding: 0px 15px;
  background-color: #fff;
  border-right: 0.3px solid #cccccc;
}

.baggage-price-txt {
  padding: 0px 15px;
  font-size: 14px !important;
  font-weight: 600;
  background-color: #fff;
  /* width: 211px !important; */
}

.total-price-label {
  font-size: 16px !important;
  font-weight: 800;
  padding: 0px 15px;
  background-color: #fff;
  border-right: 0.3px solid #cccccc;
}

.total-price-txt {
  padding: 0px 15px;
  font-size: 16px !important;
  font-weight: 800;
  background-color: #fff;
  /* width: 211px !important; */
}

.fare-item-total {
  font-size: 16px;
  font-weight: 800;
  text-align: end;
}

.booking-padding-top-64 {
  padding-top: 64px;
}

.contact-details {
  width: 100%;
  margin-top: 24px;
}

.contact-details-container {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 16px 24px;
  display: flex;
}

.contact-detail-flex {
  flex: 1;
  display: flex;
}

.contact-detail-item {
  display: flex;
  align-items: center;
}

.contact-detail-icon {
  margin-right: 12px;
  margin-left: 12px;
}

.contact-detail-text {
  font-size: 14px;
  line-height: 16px;
}

.ticket-list {
  padding: 3%;
  width: 150px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ticket-list-item {
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 0.2px;
  color: #000000 !important;
}

.ticket-list-item:hover {
  /* background-color: #340f2f; */
  color: #340f2f;
}

.contact-detail-opacity {
  opacity: 0.5;
}

.baggage-details {
  width: 100%;
  margin-top: 24px;
}

.baggage-details-container-viewBooking {
  background-color: #ffffff !important;
  padding: 10px 0px !important;
  margin: auto !important;
  display: flex !important;
  max-width: 100% !important;
  text-align: center !important;
}

.baggage-detail-flex {
  flex: 1;
  display: flex;
}

.baggage-detail-item {
  display: flex;
  align-items: center;
}

.baggage-detail-icon {
  margin-right: 12px;
  margin-left: 12px;
}

.baggage-detail-text {
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
}

.baggage-detail-opacity {
  opacity: 0.5;
}

.min-width-101 {
  min-width: 101px;
}

.more-details {
  width: 100%;
  margin-top: 24px;
}

.more-detail-item {
  display: flex;
}

.more-detail-item-icon {
  padding: 3px;
  /* background-color: #ffffff; */
  margin-right: 16px;
  border-radius: 4px;
  max-height: 24px;
}

.more-detail-item-description {
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  text-align: justify;
}

.h3-underline {
  text-decoration: underline;
}

.Ticketed {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Refunded,
.Void_Refunded {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Exchanged {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Payment_Success {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booked {
  background-color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booking_Cancelled {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}
.Hold_Cancelled,
.Hold_Cancelled_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}
.Rejected,
.Cancel_Rejected {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Cancelled {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}
.Booking_Rejected {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}
.Booking_Failed,
.Booking_On_Hold_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}
.Cancel_Requested,
.Void_Requested {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}
.Refund_Initiated,
.Void_RefundInitiated,
.Void_Initiated {
  background-color: #006644;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Ticket_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Payment_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booking_Cancellation_Failed,
.Booking_Cancelling_In_Process {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Void_Failed,
.Void_Rejected {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Refund_Failed {
  background-color: #d31204;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booking_On_Hold {
  background-color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Voided {
  background-color: #fd7e14;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Ticketing_In_Process,
.Booking_Pending {
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Exchange_In_Process {
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Refund_In_Process {
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Void_In_Process {
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Payment_In_Process {
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Booking_In_Process {
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}
.Ticketing_Pending {
  background-color: #f76218e0;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}
.Ticketing_In_Process {
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.Void_Queued {
  background-color: #ffc107;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

/*********** Below Code for New View Booking Dtls Design ************/

.flt-bkg-dtls-section {
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: default;
}

.flt-bkg-heading {
  color: #9f155a;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 3px;
  border-bottom: 1px dotted #e5e5e5;
}

.flt-bkg-itinerary-section {
  min-height: 100px;
  padding: 20px 5px;
}

.update-btn {
  margin-left: 12px;
  border-radius: 4px;
  background-color: #ffffff !important;
  color: #9f155a !important;
  border: 1px solid rgba(159, 21, 90, 0.5) !important;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px !important;
  font-family: "Cabin" !important;
  font-weight: bold !important;
}

.pnr-update-dialog-box,
.error-dialog-box {
  z-index: 1304 !important;
}

.pnr-update-dialog-box .MuiDialog-container .MuiPaper-root {
  min-width: 50%;
}

.error-dialog-box .MuiDialog-container .MuiPaper-root {
  width: 350px;
}

.pnr-update-dialog-box .main-heading-cls,
.error-dialog-box .main-heading-cls {
  color: #9f155a;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px dotted #e5e5e5;
}

.pnr-update-dialog-box .border-right,
.error-dialog-box .border-right {
  border-right: 1px dotted #cccccc;
}

.pnr-update-dialog-box .sub-heading-cls,
.error-dialog-box .sub-heading-cls {
  color: #340f2f;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 3px;
}

.pnr-update-dialog-box .normal-txt-cls,
.pnr-update-dialog-box .Mui-focused,
.error-dialog-box .normal-txt-cls,
.error-dialog-box .Mui-focused {
  color: #000000 !important;
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 3px;
}

.pnr-update-dialog-box .normal-txt-cls a,
.pnr-update-dialog-box .normal-txt-cls a:hover,
.error-dialog-box .normal-txt-cls a,
.error-dialog-box .normal-txt-cls a:hover {
  color: #0c49f2 !important;
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 3px;
  text-decoration: none !important;
}

.pnr-update-dialog-box .MuiButton-contained,
.error-dialog-box .MuiButton-contained {
  background-color: #9f155a !important;
  color: #ffffff !important;
  margin-right: 10px;
}

.pnr-update-dialog-box .MuiButton-outlined,
.error-dialog-box .MuiButton-outlined {
  border-color: #9f155a !important;
  color: #9f155a !important;
}

.pnr-update-dialog-box .pnr-dialog-action,
.error-dialog-box .error-dialog-action {
  padding: 10px;
  border-top: 1px dotted #e5e5e5;
  justify-content: center !important;
}

.vb-highlight-txt {
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
  color: #9f155a !important;
}

.col-3 {
  color: #2c2c2ce8;
}

.itenary-passenger-view-table-header-item-initial {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 0px;
}

.itenary-passenger-view-table-header-item-final {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 0px;
  padding-right: 12px;
}

.baggage-details-container-viewBooking .col-1 {
  padding: 0px !important;
}

.baggage-details-container-viewBooking .col-2 {
  padding: 0px !important;
}

.baggage-details-container-viewBooking .col-3 {
  padding: 0px !important;
}

.baggage-details-container-viewBooking .col-4 {
  padding: 0px !important;
}

.timerText {
  margin: 0px 5px;
  min-width: 130px;
  font-size: 16px;
  font-weight: bolder;
  color: #ff0000;
}

.viewBooking .itenary-passenger-table-header .header-item {
  color: #ffffff !important;
}

.viewBooking .MuiTableCell-head {
  font-weight: bold !important;
  font-size: 13px !important;
  opacity: 1 !important;
  /* text-align: center !important; */
}

.viewBooking .fareDetailsTable th {
  text-align: center !important;
}

.viewBooking .fareDetailsTable td {
  text-align: center !important;
}

.bagTableTitle {
  font-size: 18px;
  color: maroon;
  font-weight: 500;
  text-align: center;
  /* margin: 2% 0% 2% 1.5%; */
}
.cancelInvoiceContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  padding: 1vw 0;
}
.itenaryCancelled {
  opacity: 0.5;
  pointer-events: none;
}
.cancelModalItenaryListContainer {
  display: flex;
  flex-direction: column;
  padding: 0 2vw;
}
.cancelModalItenaryTopContainer {
  border: 0.5px solid red;
  padding: 1vw 2vw;
  border-radius: 50px;
  margin: 1vw 0;
}
.view-booking-container
  .MuiSheet-root
  .MuiSheet-variantOutlined
  .MuiSheet-colorNeutral
  .css-e4el6d-JoySheet-root {
  width: 65vw !important;
}

/* .mui-table-header {
	background-color: #340f34 !important;
}
.mui-table-header th {
	color: white !important;
} */

.app-icon-color {
  fill: #a0155a !important;
}

.ticket-timelimit-dialog .css-a643pv-MuiStack-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: unset !important;
  padding-top: 8px;
}

/* .MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  margin-top: -45px;
} */

.zoomable-icon {
  transition: transform 0.3s ease;
}

.zoomable-icon:hover {
  border: 1px solid rgb(182, 99, 72);
  transform: scale(1.1);
}
#seggroupPNRTable td {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.flightItineraryColumn {
  /* flex: 1 1 auto !important;
	max-width: 20% !important;
	height: auto !important;
	white-space: normal !important;
	overflow-wrap: break-word !important;
	word-break: break-word !important;
	min-width: 10% !important; */
  text-align: center !important;
}
.flightItineraryColumnHeader {
  text-align: center !important;
  /* flex: 1 1 auto !important;
	max-width: 20% !important;
	height: auto !important;
	white-space: normal !important;
	overflow-wrap: break-word !important;
	word-break: break-word !important; */
}

.copy-chkbox .MuiSvgIcon-root {
  color: white !important;
}

.copy-chkbox.MuiCheckbox-root {
  color: white !important;
}

.balance-low-dialog {
  text-align: center;
}

.dialog-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #b35d1c;
  padding: 10px 20px;
}

.dialog-content {
  text-align: center;
  padding: 10px 20px;
}

.dialog-actions {
  padding-bottom: 15px;
}
